import React, { useEffect, useState } from "react";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import Checkbox from "../../../../form/Checkbox";
import InputTime from "../../../../form/InputTime";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileService } from "../../../../../service/profileService";
import { toast } from "react-toastify";
import { setProfileData } from "../../../../../store/slice/profileSlice";
import {
  addBtn,
  commonFormLabels,
  dayNames,
  deleteBtn,
  saveChange,
} from "../../../language";

const DailySchedule = ({ data, dataLoaded }) => {
  const [finalData, setFinalData] = useState({});
  const [loaded, setLoaded] = useState(true);
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const [selectedTime, setSelectedTime] = useState({
    startTime: "",
    endTime: "",
    _id: Math.random().toString(36).substr(2, 9),
  });
  const dispatch = useDispatch();
  const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const language = useSelector((state) => state.languageSlice?.data);

  const [endTimeValue, setEndTimeValue] = useState({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  });
  const [hasError, setHasError] = useState(false);

  const [mondayData, setMondayData] = useState({
    dayNumber: 1,
    isClosed: null,
    scheduleTime: [],
  });
  const [tuesdayData, setTuesdayData] = useState({
    dayNumber: 2,
    isClosed: null,
    scheduleTime: [],
  });
  const [wednesdayData, setWednesdayData] = useState({
    dayNumber: 3,
    isClosed: null,
    scheduleTime: [],
  });
  const [thursdayData, setThursdayData] = useState({
    dayNumber: 4,
    isClosed: null,
    scheduleTime: [],
  });
  const [fridayData, setFridayData] = useState({
    dayNumber: 5,
    isClosed: null,
    scheduleTime: [],
  });
  const [saturdayData, setSaturdayData] = useState({
    dayNumber: 6,
    isClosed: null,
    scheduleTime: [],
  });
  const DailySchedule = {
    title: {
      en: "Daily Schedule",
      es: "Horario diario",
      it: "Programma giornaliero",
    },
    titleAlt: "Change Password",
    image: User,
  };
  const [sundayData, setSundayData] = useState({
    dayNumber: 7,
    isClosed: null,
    scheduleTime: [],
  });

  useEffect(() => {
    if (
      profileData &&
      profileData?.dailySchedule &&
      Array.isArray(profileData?.dailySchedule) &&
      profileData?.dailySchedule.length > 0
    ) {
      profileData.dailySchedule.forEach((elm) => {
        switch (elm?.dayNumber) {
          case 1:
            setMondayData(elm);
            break;
          case 2:
            setTuesdayData(elm);
            break;
          case 3:
            setWednesdayData(elm);
            break;
          case 4:
            setThursdayData(elm);
            break;
          case 5:
            setFridayData(elm);
            break;
          case 6:
            setSaturdayData(elm);
            break;
          case 7:
            setSundayData(elm);
            break;
          default:
            break;
        }
      });
    }
  }, [profileData]);

  const handleAddTime = (day) => {
    const newSelectedTime = {
      ...selectedTime,
      _id: Math.random().toString(36).substr(2, 9),
    };

    switch (day) {
      case 1:
        setMondayData((prev) => ({
          ...prev,
          scheduleTime: [...prev.scheduleTime, newSelectedTime],
        }));
        break;
      case 2:
        setTuesdayData((prev) => ({
          ...prev,
          scheduleTime: [...prev.scheduleTime, newSelectedTime],
        }));
        break;
      case 3:
        setWednesdayData((prev) => ({
          ...prev,
          scheduleTime: [...prev.scheduleTime, newSelectedTime],
        }));
        break;
      case 4:
        setThursdayData((prev) => ({
          ...prev,
          scheduleTime: [...prev.scheduleTime, newSelectedTime],
        }));
        break;
      case 5:
        setFridayData((prev) => ({
          ...prev,
          scheduleTime: [...prev.scheduleTime, newSelectedTime],
        }));
        break;
      case 6:
        setSaturdayData((prev) => ({
          ...prev,
          scheduleTime: [...prev.scheduleTime, newSelectedTime],
        }));
        break;
      case 7:
        setSundayData((prev) => ({
          ...prev,
          scheduleTime: [...prev.scheduleTime, newSelectedTime],
        }));
        break;
      default:
        break;
    }

    setSelectedTime({
      startTime: "",
      endTime: "",
      _id: Math.random().toString(36).substr(2, 9),
    });
  };

  const updateTime = (id, timeType, value, day) => {
    const newTime = {
      [timeType]: value && new Date(dayjs(value[0])).toISOString(),
    };
    switch (day) {
      case 1:
        setHasError(false);
        setMondayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time, ...newTime } : time
          ),
        }));
        break;
      case 11:
        setHasError(true);
        setMondayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time } : time
          ),
        }));
        break;
      case 2:
        setHasError(false);
        setTuesdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time, ...newTime } : time
          ),
        }));
        break;
      case 22:
        setHasError(true);
        setTuesdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time } : time
          ),
        }));
        break;
      case 3:
        setHasError(false);
        setWednesdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time, ...newTime } : time
          ),
        }));
        break;
      case 33:
        setHasError(true);
        setWednesdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time } : time
          ),
        }));
        break;
      case 4:
        setHasError(false);
        setThursdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time, ...newTime } : time
          ),
        }));
        break;
      case 44:
        setHasError(true);
        setThursdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time } : time
          ),
        }));
        break;
      case 5:
        setHasError(false);
        setFridayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time, ...newTime } : time
          ),
        }));
        break;
      case 55:
        setHasError(true);
        setFridayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time } : time
          ),
        }));
        break;
      case 6:
        setHasError(false);
        setSaturdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time, ...newTime } : time
          ),
        }));
        break;
      case 66:
        setHasError(true);
        setSaturdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time } : time
          ),
        }));
        break;
      case 7:
        setHasError(false);
        setSundayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time, ...newTime } : time
          ),
        }));
        break;
      case 77:
        setHasError(true);
        setSundayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.map((time) =>
            time._id === id ? { ...time } : time
          ),
        }));
        break;
      default:
        break;
    }
  };

  const deleteTime = (id, day) => {
    switch (day) {
      case 1:
        setMondayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.filter((time) => time._id !== id),
        }));
        break;
      case 2:
        setTuesdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.filter((time) => time._id !== id),
        }));
        break;
      case 3:
        setWednesdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.filter((time) => time._id !== id),
        }));
        break;
      case 4:
        setThursdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.filter((time) => time._id !== id),
        }));
        break;
      case 5:
        setFridayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.filter((time) => time._id !== id),
        }));
        break;
      case 6:
        setSaturdayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.filter((time) => time._id !== id),
        }));
        break;
      case 7:
        setSundayData((prev) => ({
          ...prev,
          scheduleTime: prev.scheduleTime.filter((time) => time._id !== id),
        }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let monday = {};
    mondayData?.scheduleTime &&
      mondayData?.scheduleTime.length > 0 &&
      mondayData?.scheduleTime.forEach((item, index) => {
        monday[`dailySchedule[0][scheduleTime][${index}][startTime]`] =
          item?.startTime;
        monday[`dailySchedule[0][scheduleTime][${index}][endTime]`] =
          item?.endTime;
      });
    let tuesday = {};
    tuesdayData?.scheduleTime &&
      tuesdayData?.scheduleTime.length > 0 &&
      tuesdayData?.scheduleTime.forEach((item, index) => {
        tuesday[`dailySchedule[1][scheduleTime][${index}][startTime]`] =
          item?.startTime;
        tuesday[`dailySchedule[1][scheduleTime][${index}][endTime]`] =
          item?.endTime;
      });
    let wednesday = {};
    wednesdayData?.scheduleTime &&
      wednesdayData?.scheduleTime.length > 0 &&
      wednesdayData?.scheduleTime.forEach((item, index) => {
        wednesday[`dailySchedule[2][scheduleTime][${index}][startTime]`] =
          item?.startTime;
        wednesday[`dailySchedule[2][scheduleTime][${index}][endTime]`] =
          item?.endTime;
      });
    let thursday = {};
    thursdayData?.scheduleTime &&
      thursdayData?.scheduleTime.length > 0 &&
      thursdayData?.scheduleTime.forEach((item, index) => {
        thursday[`dailySchedule[3][scheduleTime][${index}][startTime]`] =
          item?.startTime;
        thursday[`dailySchedule[3][scheduleTime][${index}][endTime]`] =
          item?.endTime;
      });
    let friday = {};
    fridayData?.scheduleTime &&
      fridayData?.scheduleTime.length > 0 &&
      fridayData?.scheduleTime.forEach((item, index) => {
        friday[`dailySchedule[4][scheduleTime][${index}][startTime]`] =
          item?.startTime;
        friday[`dailySchedule[4][scheduleTime][${index}][endTime]`] =
          item?.endTime;
      });
    let saturday = {};
    saturdayData?.scheduleTime &&
      saturdayData?.scheduleTime.length > 0 &&
      saturdayData?.scheduleTime.forEach((item, index) => {
        saturday[`dailySchedule[5][scheduleTime][${index}][startTime]`] =
          item?.startTime;
        saturday[`dailySchedule[5][scheduleTime][${index}][endTime]`] =
          item?.endTime;
      });
    let sunday = {};
    sundayData?.scheduleTime &&
      sundayData?.scheduleTime.length > 0 &&
      sundayData?.scheduleTime.forEach((item, index) => {
        sunday[`dailySchedule[6][scheduleTime][${index}][startTime]`] =
          item?.startTime;
        sunday[`dailySchedule[6][scheduleTime][${index}][endTime]`] =
          item?.endTime;
      });

    setFinalData({
      "dailySchedule[0][isClosed]": mondayData?.isClosed,
      "dailySchedule[1][isClosed]": tuesdayData?.isClosed,
      "dailySchedule[2][isClosed]": wednesdayData?.isClosed,
      "dailySchedule[3][isClosed]": thursdayData?.isClosed,
      "dailySchedule[4][isClosed]": fridayData?.isClosed,
      "dailySchedule[5][isClosed]": saturdayData?.isClosed,
      "dailySchedule[6][isClosed]": sundayData?.isClosed,
      "dailySchedule[0][dayNumber]": 1,
      "dailySchedule[1][dayNumber]": 2,
      "dailySchedule[2][dayNumber]": 3,
      "dailySchedule[3][dayNumber]": 4,
      "dailySchedule[4][dayNumber]": 5,
      "dailySchedule[5][dayNumber]": 6,
      "dailySchedule[6][dayNumber]": 7,
      ...monday,
      ...tuesday,
      ...wednesday,
      ...thursday,
      ...friday,
      ...saturday,
      ...sunday,
    });
  }, [
    mondayData,
    tuesdayData,
    wednesdayData,
    thursdayData,
    fridayData,
    saturdayData,
    sundayData,
  ]);

  const handleSubmit = () => {
    let hasInvalidValue = false;

    for (let key in finalData) {
      if (
        finalData[key] === null ||
        finalData[key] === undefined ||
        (!hasError && finalData[key] === "")
      ) {
        hasInvalidValue = true;
      }
    }

    if (hasInvalidValue) {
      toast.error("Any field should not be left empty!");
      return;
    }
    if (hasError) {
      toast.error("End time should be greater than start time, please check!");
      return;
    }
    //api call to update data
    setLoaded(false);
    updateProfileService({
      ...finalData,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        setHasError(false);
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));
      }
    });
  };

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {DailySchedule?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{
              __html: DailySchedule?.title?.[language],
            }}
          ></h2>
        )}
      </div>

      {/* Monday */}
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="relative mb-8">
            <div className="relative flex items-center gap-1 border-b pb-2 mb-3 border-dashed border-neutral-300">
              <div className="w-1 h-5 bg-[#1C55A7]"></div>
              <div className="flex justify-between w-full">
                <div className="text-base text-prontopsy-blue text-left">
                  {dayNames?.monday?.[language]}
                </div>
                <div className="relative">
                  <Checkbox
                    checkboxLabel={"Close"}
                    isChecked={mondayData?.isClosed}
                    onChange={(e) =>
                      e.target.checked
                        ? setMondayData((pre) => ({
                            ...pre,
                            scheduleTime: [],
                            isClosed: e.target.checked,
                          }))
                        : setMondayData((pre) => ({
                            ...pre,
                            isClosed: e.target.checked,
                          }))
                    }
                  />
                </div>
              </div>
            </div>
            {mondayData?.scheduleTime.length > 0 &&
              mondayData?.scheduleTime.map((elm) => (
                <div className="md:mb-4 mb-0" key={elm?._id}>
                  <div className="md:flex block md:gap-5 gap-2 items-center">
                  <div className="flex w-full gap-5 items-center">
                    <div className="mt-2 w-[50%]">
                      
                      <div className="relative">
                        <InputTime
                          value={elm?.startTime}
                          label={`*${commonFormLabels?.startTime?.[language]}`}
                          onChange={(val) =>
                            updateTime(elm._id, "startTime", val, 1)
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          label={`*${commonFormLabels?.endTime?.[language]}`}
                          value={elm?.endTime}
                          onChange={(val) => {
                            setEndTimeValue((pre) => ({ ...pre, monday: val }));
                            if (new Date(val) > new Date(elm?.startTime)) {
                              updateTime(elm._id, "endTime", val, 1);
                            } else {
                              updateTime(elm._id, "endTime", null, 11);
                            }
                          }}
                          errorType={
                            endTimeValue?.monday &&
                            new Date(endTimeValue?.monday) <=
                              new Date(elm?.startTime) &&
                            "danger"
                          }
                          errorText={
                            "End time should be greater than start time."
                          }
                        />
                      </div>
                    </div>
                    </div>
                    {mondayData?.scheduleTime.length > 1 && (
                      <div className="mt-2 md:w-1/12 w-full">
                        <div className="relative md:mt-6 mt-0 flex justify-end">
                          <Button
                            buttonIcon={"fa-solid fa-trash"}
                            buttonIconPosition={"center"}
                            buttonLabel={deleteBtn?.[language]}
                            buttonFunction={() => deleteTime(elm?._id, 1)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}

            {/* Add More */}
            <div className="mt-2">
              <div className="relative md:mt-6 mt-0">
                <Button
                  buttonIcon={"fa-solid fa-trash"}
                  buttonIconPosition={"center"}
                  buttonLabel={addBtn?.[language]}
                  buttonFunction={() => handleAddTime(1)}
                  isDisable={mondayData?.isClosed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tuesday */}
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="relative mb-8">
            <div className="relative flex items-center gap-1 border-b pb-2 mb-3 border-dashed border-neutral-300">
              <div className="w-1 h-5 bg-[#1C55A7]"></div>
              <div className="flex justify-between w-full">
                <div className="text-base text-prontopsy-blue text-left">
                  {dayNames?.tuesday?.[language]}
                </div>
                <div className="relative">
                  <Checkbox
                    checkboxLabel={"Close"}
                    isChecked={tuesdayData?.isClosed}
                    onChange={(e) =>
                      e.target.checked
                        ? setTuesdayData((pre) => ({
                            ...pre,
                            scheduleTime: [],
                            isClosed: e.target.checked,
                          }))
                        : setTuesdayData((pre) => ({
                            ...pre,
                            isClosed: e.target.checked,
                          }))
                    }
                  />
                </div>
              </div>
            </div>
            {tuesdayData?.scheduleTime.length > 0 &&
              tuesdayData?.scheduleTime.map((elm) => (
                <div className="md:mb-4 mb-0" key={elm?._id}>
                  <div className="md:flex block w-full md:gap-5 gap-2 items-center">
                    <div className="flex w-full gap-5 items-center">
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          value={elm?.startTime || ""}
                          label={`*${commonFormLabels?.startTime?.[language]}`}
                          onChange={(val) =>
                            updateTime(elm._id, "startTime", val, 2)
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          label={`*${commonFormLabels?.endTime?.[language]}`}
                          value={elm?.endTime || ""}
                          // onChange={(val) =>
                          //   updateTime(elm._id, "endTime", val, 2)
                          // }
                          onChange={(val) => {
                            setEndTimeValue((pre) => ({
                              ...pre,
                              tuesday: val,
                            }));
                            if (new Date(val) > new Date(elm?.startTime)) {
                              updateTime(elm._id, "endTime", val, 2);
                            } else {
                              updateTime(elm._id, "endTime", null, 22);
                            }
                          }}
                          errorType={
                            endTimeValue?.tuesday &&
                            new Date(endTimeValue?.tuesday) <=
                              new Date(elm?.startTime) &&
                            "danger"
                          }
                          errorText={
                            "End time should be greater than start time."
                          }
                        />
                      </div>
                    </div>
                    </div>
                    {tuesdayData?.scheduleTime.length > 1 && (
                      <div className="mt-2 md:w-1/12 w-full">
                        <div className="relative md:mt-6 mt-0 flex justify-end">
                          <Button
                            buttonIcon={"fa-solid fa-trash"}
                            buttonIconPosition={"center"}
                            buttonLabel={deleteBtn?.[language]}
                            buttonFunction={() => deleteTime(elm?._id, 2)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}

            <div className="mt-2">
              <div className="relative md:mt-6 mt-0">
                <Button
                  buttonIcon={"fa-solid fa-trash"}
                  buttonIconPosition={"center"}
                  buttonLabel={addBtn?.[language]}
                  buttonFunction={() => handleAddTime(2)}
                  isDisable={tuesdayData?.isClosed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Wednesday */}
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="relative mb-8">
            <div className="relative flex items-center gap-1 border-b pb-2 mb-3 border-dashed border-neutral-300">
              <div className="w-1 h-5 bg-[#1C55A7]"></div>
              <div className="flex justify-between w-full">
                <div className="text-base text-prontopsy-blue text-left">
                  {dayNames?.wednesday?.[language]}
                </div>
                <div className="relative">
                  <Checkbox
                    checkboxLabel={"Close"}
                    isChecked={wednesdayData?.isClosed}
                    onChange={(e) =>
                      e.target.checked
                        ? setWednesdayData((pre) => ({
                            ...pre,
                            scheduleTime: [],
                            isClosed: e.target.checked,
                          }))
                        : setWednesdayData((pre) => ({
                            ...pre,
                            isClosed: e.target.checked,
                          }))
                    }
                  />
                </div>
              </div>
            </div>
            {wednesdayData?.scheduleTime.length > 0 &&
              wednesdayData?.scheduleTime.map((elm) => (
                <div className="md:mb-4 mb-0" key={elm?._id}>
                  <div className="md:flex block w-full md:gap-5 gap-2 items-center">
                    <div className="flex w-full gap-5 items-center">
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          value={elm?.startTime || ""}
                          label={`*${commonFormLabels?.startTime?.[language]}`}
                          onChange={(val) =>
                            updateTime(elm._id, "startTime", val, 3)
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          label={`*${commonFormLabels?.endTime?.[language]}`}
                          value={elm?.endTime || ""}
                          // onChange={(val) =>
                          //   updateTime(elm._id, "endTime", val, 3)
                          // }
                          onChange={(val) => {
                            setEndTimeValue((pre) => ({
                              ...pre,
                              wednesday: val,
                            }));
                            if (new Date(val) > new Date(elm?.startTime)) {
                              updateTime(elm._id, "endTime", val, 3);
                            } else {
                              updateTime(elm._id, "endTime", null, 33);
                            }
                          }}
                          errorType={
                            endTimeValue?.wednesday &&
                            new Date(endTimeValue?.wednesday) <=
                              new Date(elm?.startTime) &&
                            "danger"
                          }
                          errorText={
                            "End time should be greater than start time."
                          }
                        />
                      </div>
                    </div>
                    </div>
                    {wednesdayData?.scheduleTime.length > 1 && (
                      <div className="mt-2 md:w-1/12 w-full">
                        <div className="relative md:mt-6 flex justify-end">
                          <Button
                            buttonIcon={"fa-solid fa-trash"}
                            buttonIconPosition={"center"}
                            buttonLabel={deleteBtn?.[language]}
                            buttonFunction={() => deleteTime(elm?._id, 3)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}

            <div className="mt-2">
              <div className="relative md:mt-6 mt-0">
                <Button
                  buttonIcon={"fa-solid fa-trash"}
                  buttonIconPosition={"center"}
                  buttonLabel={addBtn?.[language]}
                  buttonFunction={() => handleAddTime(3)}
                  isDisable={wednesdayData?.isClosed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Thursday */}
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="relative mb-8">
            <div className="relative flex items-center gap-1 border-b pb-2 mb-3 border-dashed border-neutral-300">
              <div className="w-1 h-5 bg-[#1C55A7]"></div>
              <div className="flex justify-between w-full">
                <div className="text-base text-prontopsy-blue text-left">
                  {dayNames?.thursday?.[language]}
                </div>
                <div className="relative">
                  <Checkbox
                    checkboxLabel={"Close"}
                    isChecked={thursdayData?.isClosed}
                    onChange={(e) =>
                      e.target.checked
                        ? setThursdayData((pre) => ({
                            ...pre,
                            scheduleTime: [],
                            isClosed: e.target.checked,
                          }))
                        : setThursdayData((pre) => ({
                            ...pre,
                            isClosed: e.target.checked,
                          }))
                    }
                  />
                </div>
              </div>
            </div>
            {thursdayData?.scheduleTime.length > 0 &&
              thursdayData?.scheduleTime.map((elm) => (
                <div className="md:mb-4 mb-0" key={elm?._id}>
                  <div className="md:flex block w-full md:gap-5 gap-2 items-center">
                    <div className="flex w-full gap-5 items-center">
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          value={elm?.startTime || ""}
                          label={`*${commonFormLabels?.startTime?.[language]}`}
                          onChange={(val) =>
                            updateTime(elm._id, "startTime", val, 4)
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          label={`*${commonFormLabels?.endTime?.[language]}`}
                          value={elm?.endTime || ""}
                          // onChange={(val) =>
                          //   updateTime(elm._id, "endTime", val, 4)
                          // }
                          onChange={(val) => {
                            setEndTimeValue((pre) => ({
                              ...pre,
                              thursday: val,
                            }));
                            if (new Date(val) > new Date(elm?.startTime)) {
                              updateTime(elm._id, "endTime", val, 4);
                            } else {
                              updateTime(elm._id, "endTime", null, 44);
                            }
                          }}
                          errorType={
                            endTimeValue?.thursday &&
                            new Date(endTimeValue?.thursday) <=
                              new Date(elm?.startTime) &&
                            "danger"
                          }
                          errorText={
                            "End time should be greater than start time."
                          }
                        />
                      </div>
                    </div>
                    </div>
                    {thursdayData?.scheduleTime.length > 1 && (
                      <div className="mt-2 md:w-1/12 w-full">
                        <div className="relative md:mt-6 mt-0 flex justify-end">
                          <Button
                            buttonIcon={"fa-solid fa-trash"}
                            buttonIconPosition={"center"}
                            buttonLabel={deleteBtn?.[language]}
                            buttonFunction={() => deleteTime(elm?._id, 4)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}

            <div className="mt-2">
              <div className="relative md:mt-6 mt-0">
                <Button
                  buttonIcon={"fa-solid fa-trash"}
                  buttonIconPosition={"center"}
                  buttonLabel={addBtn?.[language]}
                  buttonFunction={() => handleAddTime(4)}
                  isDisable={thursdayData?.isClosed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Friday */}
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="relative mb-8">
            <div className="relative flex items-center gap-1 border-b pb-2 mb-3 border-dashed border-neutral-300">
              <div className="w-1 h-5 bg-[#1C55A7]"></div>
              <div className="flex justify-between w-full">
                <div className="text-base text-prontopsy-blue text-left">
                  {dayNames?.friday?.[language]}
                </div>
                <div className="relative">
                  <Checkbox
                    checkboxLabel={"Close"}
                    isChecked={fridayData?.isClosed}
                    onChange={(e) =>
                      e.target.checked
                        ? setFridayData((pre) => ({
                            ...pre,
                            scheduleTime: [],
                            isClosed: e.target.checked,
                          }))
                        : setFridayData((pre) => ({
                            ...pre,
                            isClosed: e.target.checked,
                          }))
                    }
                  />
                </div>
              </div>
            </div>
            {fridayData?.scheduleTime.length > 0 &&
              fridayData?.scheduleTime.map((elm) => (
                <div className="md:mb-4 mb-0" key={elm?._id}>
                  <div className="md:flex block w-full md:gap-5 gap-2 items-center">
                    <div className="flex w-full gap-5 items-center">
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          value={elm?.startTime || ""}
                          label={`*${commonFormLabels?.startTime?.[language]}`}
                          onChange={(val) =>
                            updateTime(elm._id, "startTime", val, 5)
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          label={`*${commonFormLabels?.endTime?.[language]}`}
                          value={elm?.endTime || ""}
                          // onChange={(val) =>
                          //   updateTime(elm._id, "endTime", val, 5)
                          // }
                          onChange={(val) => {
                            setEndTimeValue((pre) => ({ ...pre, friday: val }));
                            if (new Date(val) > new Date(elm?.startTime)) {
                              updateTime(elm._id, "endTime", val, 5);
                            } else {
                              updateTime(elm._id, "endTime", null, 55);
                            }
                          }}
                          errorType={
                            endTimeValue?.friday &&
                            new Date(endTimeValue?.friday) <=
                              new Date(elm?.startTime) &&
                            "danger"
                          }
                          errorText={
                            "End time should be greater than start time."
                          }
                        />
                      </div>
                    </div>
                    </div>
                    {fridayData?.scheduleTime.length > 1 && (
                      <div className="mt-2 md:w-1/12 w-full">
                        <div className="relative md:mt-6 mt-0 flex justify-end">
                          <Button
                            buttonIcon={"fa-solid fa-trash"}
                            buttonIconPosition={"center"}
                            buttonLabel={deleteBtn?.[language]}
                            buttonFunction={() => deleteTime(elm?._id, 5)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}

            <div className="mt-2">
              <div className="relative md:mt-6 mt-0">
                <Button
                  buttonIcon={"fa-solid fa-trash"}
                  buttonIconPosition={"center"}
                  buttonLabel={addBtn?.[language]}
                  buttonFunction={() => handleAddTime(5)}
                  isDisable={fridayData?.isClosed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Saturday */}
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="relative mb-8">
            <div className="relative flex items-center gap-1 border-b pb-2 mb-3 border-dashed border-neutral-300">
              <div className="w-1 h-5 bg-[#1C55A7]"></div>
              <div className="flex justify-between w-full">
                <div className="text-base text-prontopsy-blue text-left">
                  {dayNames?.saturday?.[language]}
                </div>
                <div className="relative">
                  <Checkbox
                    checkboxLabel={"Close"}
                    isChecked={saturdayData?.isClosed && saturdayData?.isClosed}
                    onChange={(e) => {
                      e.target.checked
                        ? setSaturdayData((pre) => ({
                            ...pre,
                            scheduleTime: [],
                            isClosed: e.target.checked,
                          }))
                        : setSaturdayData((pre) => ({
                            ...pre,
                            isClosed: e.target.checked,
                          }));
                    }}
                  />
                </div>
              </div>
            </div>
            {saturdayData?.scheduleTime.length > 0 &&
              saturdayData?.scheduleTime.map((elm) => (
                <div className="md:mb-4 mb-0" key={elm?._id}>
                  <div className="md:flex block w-full md:gap-5 gap-2 items-center">
                    <div className="flex w-full gap-5 items-center">
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          value={elm?.startTime || ""}
                          label={`*${commonFormLabels?.startTime?.[language]}`}
                          onChange={(val) =>
                            updateTime(elm._id, "startTime", val, 6)
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          label={`*${commonFormLabels?.endTime?.[language]}`}
                          value={elm?.endTime || ""}
                          // onChange={(val) =>
                          //   updateTime(elm._id, "endTime", val, 6)
                          // }
                          onChange={(val) => {
                            setEndTimeValue((pre) => ({
                              ...pre,
                              saturday: val,
                            }));
                            if (new Date(val) > new Date(elm?.startTime)) {
                              updateTime(elm._id, "endTime", val, 6);
                            } else {
                              updateTime(elm._id, "endTime", null, 66);
                            }
                          }}
                          errorType={
                            endTimeValue?.saturday &&
                            new Date(endTimeValue?.saturday) <=
                              new Date(elm?.startTime) &&
                            "danger"
                          }
                          errorText={
                            "End time should be greater than start time."
                          }
                        />
                      </div>
                    </div>
                    </div>
                    {saturdayData?.scheduleTime.length > 1 && (
                      <div className="mt-2 md:w-1/12 w-full">
                        <div className="relative md:mt-6 mt-0 flex justify-end">
                          <Button
                            buttonIcon={"fa-solid fa-trash"}
                            buttonIconPosition={"center"}
                            buttonLabel={deleteBtn?.[language]}
                            buttonFunction={() => deleteTime(elm?._id, 6)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}

            <div className="mt-2">
              <div className="relative md:mt-6 mt-0">
                <Button
                  buttonIcon={"fa-solid fa-trash"}
                  buttonIconPosition={"center"}
                  buttonLabel={addBtn?.[language]}
                  buttonFunction={() => handleAddTime(6)}
                  isDisable={saturdayData?.isClosed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sunday */}
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="relative mb-8">
            <div className="relative flex items-center gap-1 border-b pb-2 mb-3 border-dashed border-neutral-300">
              <div className="w-1 h-5 bg-[#1C55A7]"></div>
              <div className="flex justify-between w-full">
                <div className="text-base text-prontopsy-blue text-left">
                  {dayNames?.sunday?.[language]}
                </div>
                <div className="relative">
                  <Checkbox
                    checkboxLabel={"Close"}
                    isChecked={sundayData?.isClosed}
                    onChange={(e) =>
                      e.target.checked
                        ? setSundayData((pre) => ({
                            ...pre,
                            scheduleTime: [],
                            isClosed: e.target.checked,
                          }))
                        : setSundayData((pre) => ({
                            ...pre,
                            isClosed: e.target.checked,
                          }))
                    }
                  />
                </div>
              </div>
            </div>
            {sundayData?.scheduleTime.length > 0 &&
              sundayData?.scheduleTime.map((elm) => (
                <div className="md:mb-4 mb-0" key={elm?._id}>
                  <div className="md:flex block w-full md:gap-5 gap-2 items-center">
                    <div className="flex w-full gap-5 items-center">
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          value={elm?.startTime || ""}
                          label={`*${commonFormLabels?.startTime?.[language]}`}
                          onChange={(val) =>
                            updateTime(elm._id, "startTime", val, 7)
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-2 w-[50%]">
                      <div className="relative">
                        <InputTime
                          label={`*${commonFormLabels?.endTime?.[language]}`}
                          value={elm?.endTime || ""}
                          // onChange={(val) =>
                          //   updateTime(elm._id, "endTime", val, 7)
                          // }
                          onChange={(val) => {
                            setEndTimeValue((pre) => ({ ...pre, sunday: val }));
                            if (new Date(val) > new Date(elm?.startTime)) {
                              updateTime(elm._id, "endTime", val, 7);
                            } else {
                              updateTime(elm._id, "endTime", null, 77);
                            }
                          }}
                          errorType={
                            endTimeValue?.sunday &&
                            new Date(endTimeValue?.sunday) <=
                              new Date(elm?.startTime) &&
                            "danger"
                          }
                          errorText={
                            "End time should be greater than start time."
                          }
                        />
                      </div>
                    </div>
                    </div>
                    {sundayData?.scheduleTime.length > 1 && (
                      <div className="mt-2 md:w-1/12 w-full">
                        <div className="relative md:mt-6 mt-0 flex justify-end">
                          <Button
                            buttonIcon={"fa-solid fa-trash"}
                            buttonIconPosition={"center"}
                            buttonLabel={deleteBtn?.[language]}
                            buttonFunction={() => deleteTime(elm?._id, 7)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}

            <div className="mt-2">
              <div className="relative md:mt-6 mt-0">
                <Button
                  buttonIcon={"fa-solid fa-trash"}
                  buttonIconPosition={"center"}
                  buttonLabel={addBtn?.[language]}
                  buttonFunction={() => handleAddTime(7)}
                  isDisable={sundayData?.isClosed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex items-center gap-2 justify-end">
        <Button
          buttonLabel={saveChange?.[language]}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-blue !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default DailySchedule;
