import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import Checkbox from "../../../../form/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguageList } from "../../../../../service/languageService";
import { commonFormLabels, saveChange } from "../../../language";
import { updateProfileService } from "../../../../../service/profileService";
import { toast } from "react-toastify";
import { setProfileData } from "../../../../../store/slice/profileSlice";
const LanguageInfo = ({ data, dataLoaded }) => {
  const language = useSelector((state) => state.languageSlice.data);
  const [loaded, setLoaded] = useState(true);
  const [languageList, setLanguageList] = useState([]);
  const [formData, setFormData] = useState({});
  const [langTemp, setlangTemp] = useState({});
  const dispatch = useDispatch();
  const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  let languageInformation = {
    en: "Language Information",
    es: "Información del idioma",
    it: "Informazioni sulla lingua",
  };

  const LanguageInfo = {
    title: languageInformation?.[language],
    titleAlt: "Change Password",
    image: User,
  };
  useEffect(() => {
    setFormData({ language: data?.language?._id });
    setlangTemp({
      value: data && data?.language?._id,
      name: data && data?.language?.name,
    });
  }, [data]);

  useEffect(() => {
    fetchLanguageList(setLanguageList, setLoaded);
  }, []);

  const handleFormData = (e) => {
    setFormData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };
  const langData =
    languageList.length > 0 &&
    languageList.map((elm) => ({
      value: elm._id,
      name: elm.name,
    }));

  const handleSubmit = () => {
    //api call to update data
    if (!formData?.language) {
      toast.info("Language is required!");
      return;
    }
    setLoaded(false);
    updateProfileService({
      ...formData,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));
      }
    });
  };

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {LanguageInfo?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{ __html: LanguageInfo?.title }}
          ></h2>
        )}
      </div>
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="grid grid-cols-12 gap-3 mb-4">
            <div className="w-full col-span-12">
              <Select
                label={`*${commonFormLabels?.language?.[language]} :`}
                selectedValue={
                  langTemp && {
                    ...langTemp,
                  }
                }
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                dropdownButtonClass={"!h-12 !bg-transparent"}
                isSearch={true}
                dropdownData={langData}
                dropdownClass={"w-full"}
                handleSelect={(val) => {
                  setlangTemp(val);
                  handleFormData({
                    target: {
                      value: val?.value,
                      name: "language",
                    },
                  });
                }}
                loaded={dataLoaded}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex items-center gap-2 justify-end">
        <Button
          buttonLabel={saveChange?.[language]}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default LanguageInfo;
