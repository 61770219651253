import React, { useState, useEffect } from "react";
import Modal from "../../elements/Modal";
import Button from "../../form/Button";
import { useNavigate } from "react-router-dom";
import {
  appointmentClose,
  reviewAddFront,
} from "../../../service/videoCallService";
import { toast } from "react-toastify";
import { useSocket } from "../../socket/SocketProvider";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import useMediaStream from "../../elements/hooks/useMediaStream";
import usePageVisibility from "../../../util/PageVisibility";
import useBatteryStatus from "../../elements/hooks/useBatteryStatus";
// import useBeforeUnload from "../../elements/hooks/useBeforeUnload";
// import useBatteryStatus from "../../elements/hooks/useBatteryStatus";

dayjs.extend(duration);

const UserChatsHeader = ({
  toggleVideo,
  toggleAudio,
  isFullscreen,
  isChatVisible,
  isAudioEnabled,
  isVideoEnabled,
  toggleFullscreen,
  setIsAudioEnabled,
  setIsVideoEnabled,
  isInterviewStarted,
  toggleChatVisibility,
  stopStreaming = () => {},
}) => {
  const navigate = useNavigate();
  const socket = useSocket();
  const token = localStorage.getItem("auth_token");
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { closeMediaDevices } = useMediaStream();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const appointmentData = JSON.parse(localStorage.getItem("appointmentData"));
  const [isOneHourPassed, setIsOneHourPassed] = useState(false);

  // useBeforeUnload();
  // console.log(appointmentData?.appointmentStartTime);
  const calculateElapsedTime = () => {
    const currentTime = dayjs();
    const time =
      appointmentData &&
      appointmentData.appointmentId &&
      appointmentData.sessionId
        ? appointmentData?.appointmentStartTime
        : currentTime;
    const startTime = dayjs(
      // appointmentData?.appointmentStartTime || currentTime
      time
    );

    const duration = dayjs.duration(currentTime.diff(startTime));

    const hours = String(duration.hours()).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    // Check if elapsed time is one hour or more
    if (duration.asHours() >= 1 && !isOneHourPassed) {
      setIsOneHourPassed(true);
    }

    return `${hours} : ${minutes} : ${seconds}`;
  };

  useEffect(() => {
    setElapsedTime(calculateElapsedTime());

    const interval = setInterval(() => {
      setElapsedTime(calculateElapsedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [appointmentData]);
  // console.log(elapsedTime, "timer");

  const handleVideoToggle = () => {
    setIsVideoEnabled(!isVideoEnabled);
    toggleVideo(!isVideoEnabled);
  };

  const handleAudioToggle = () => {
    setIsAudioEnabled(!isAudioEnabled);
    toggleAudio(!isAudioEnabled);
  };

  //--------------Battery Percentage------------------
  const { level, charging } = useBatteryStatus();
  useEffect(() => {
    if (charging === false && Math.round(level * 100) === 20) {
      alert("Your battery is low!");
    } else if (charging === false && Math.round(level * 100) <= 5) {
      stopStreaming();
      socket &&
        socket.emit("videoSessionDisconnect", {
          disconnect: true,
          appointmentId: appointmentData?.appointmentId,
        });
      socket &&
        userDetails?.roleCode == "consultant" &&
        socket.emit("consultantBusy", {
          consultantId: userDetails?._id,
          isBusy: false,
        });
      alert(
        "Your battery is critically low! Plug in your charger to continue."
      );
      navigate("/appointments");
    }
  }, [level, charging]);
  //------------------------------------------------------
  //----------------------Tab / Brawser Close-----------------------
  // const handleBeforeUnload = (event) => {
  // console.log(event, "eeeeeee");
  // event.preventDefault();
  // event.returnValue = "";
  // socket.emit("userOffline", userDetails?._id);
  // socket.emit("videoSessionDisconnect", {
  //   disconnect: true,
  //   appointmentId: appointmentData?.appointmentId,
  // });
  // };
  // window.addEventListener("beforeunload", handleBeforeUnload);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     stopStreaming();
  //     closeMediaDevices();
  // socket &&
  //   socket.emit("videoSessionDisconnect", {
  //     disconnect: true,
  //     appointmentId: appointmentData?.appointmentId,
  //   });

  //     event.preventDefault();
  //     event.returnValue = "";
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [socket]);

  socket &&
    appointmentData &&
    appointmentData?.callerData?.consultant?.id &&
    socket.emit("consultantBusy", {
      consultantId: appointmentData?.callerData?.consultant?.id,
      isBusy: true,
    });

  const onConfirm = () => {
    setIsOneHourPassed(false);
    setLoading(true);
    appointmentData &&
      appointmentData?.appointmentId &&
      appointmentClose({ appointmentId: appointmentData?.appointmentId }).then(
        (res) => {
          if (res?.data?.status == 200 || res?.data?.status == 201) {
            setLoading(false);
            socket &&
              socket.emit("videoSessionDisconnect", {
                disconnect: true,
                appointmentId: appointmentData?.appointmentId,
              });

            socket &&
              userDetails?.roleCode == "consultant" &&
              socket.emit("consultantBusy", {
                consultantId: userDetails?._id,
                isBusy: false,
              });

            // navigator.mediaDevices
            //   .getUserMedia({ video: true, audio: true })
            //   .then((mediaStream) => {
            //     // Get all the tracks (both audio and video)
            //     const tracks = mediaStream.getTracks();

            //     // Stop each track to turn off the camera and microphone
            //     tracks.forEach((track) => track.stop());

            //     console.log("Camera and microphone stopped");
            //   })
            //   .catch((error) => {
            //     console.error("Error accessing media devices:", error);
            //   });

            setIsOpen(false);
            if (userDetails?.roleCode === "user") {
              window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
            } else {
              window.location.href = "/appointments";
              localStorage.removeItem("appointmentData");
            }
            stopStreaming();
          } else {
            setLoading(false);
            toast.error(res?.data?.message);
            // navigate("/");
            if (userDetails?.roleCode === "user") {
              window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
            } else {
              window.location.href = "/appointments";
              localStorage.removeItem("appointmentData");
            }
            localStorage.removeItem("appointmentData");
          }
        }
      );
  };

  //one houre ---------------

  // useEffect(() => {
  //   if (isOneHourPassed === true) {
  //     toast.info("You have reached your maximum calling duration!");
  //     onConfirm();
  //   }
  // }, [isOneHourPassed === true]);

  useSocket("videoSessionDisconnectResponse", async (data) => {
    if (data?.disconnect === true) {
      // closeMediaDevices();
      // onConfirm();
      if (userDetails?.roleCode === "user") {
        window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
      } else {
        window.location.href = "/appointments";
        localStorage.removeItem("appointmentData");
      }
      // if (userDetails?.roleCode == "user") {
      //   navigate(`/review-appointment/${appointmentData?.appointmentId}`);
      // } else {
      //   navigate("/appointments");
      //   localStorage.removeItem("appointmentData");
      // }
      stopStreaming();
    }
  });
  usePageVisibility(token, socket, userDetails?._id);
  return (
    <div className="md:flex block bg-slate-900 px-8 py-4 absolute bottom-0 w-full">
      <div className="flex items-center space-x-5 text-gray-300">
        <div>
          <i className="fa-solid fa-table-cells-large"></i>
        </div>
        <div className="flex space-x-2">
          <div className="text-red-600">
            <i className="fa-sharp fa-regular fa-record-vinyl"></i>
          </div>
          <div>{elapsedTime}</div>
        </div>
      </div>
      <div className="flex items-center text-gray-100 ml-auto space-x-10 justify-between md:mt-0 mt-3">
        {/* <div className="flex items-center space-x-6 text-xl border-r-2 border-gray-300 pr-6 text-gray-600">
          {isInterviewStarted && (
            <button
              onClick={toggleChatVisibility}
              className={`px-2 py-1 rounded-lg transition-colors duration-300 text-white hover:bg-blue-400 ${
                isChatVisible ? "bg-blue-500 " : ""
              }`}
              aria-label="Toggle Chat Visibility"
            >
              <i className="fa-light fa-comment-lines"></i>
            </button>
          )}
        </div> */}

        {isInterviewStarted && (
          <div className="space-x-6 text-xl">
            {appointmentData?.mode == "video" && (
              <button onClick={handleVideoToggle}>
                <i
                  className={`fa-solid fa-video ${
                    !isVideoEnabled ? "text-red-500" : ""
                  }`}
                ></i>
              </button>
            )}
            <button onClick={handleAudioToggle}>
              <i
                className={`fa-solid fa-microphone ${
                  !isAudioEnabled ? "text-red-500" : ""
                }`}
              ></i>
            </button>
          </div>
        )}
        <button
          onClick={toggleFullscreen}
          className={`bg-blue-500 px-2 py-1 rounded text-base ${
            isFullscreen ? "bg-blue-600" : "bg-blue-500"
          }`}
        >
          {isFullscreen ? (
            <i class="fa-solid fa-compress-wide"></i>
          ) : (
            <i class="fa-solid fa-expand"></i>
          )}
        </button>
        {isInterviewStarted && (
          <Button
            buttonLabel={"Leave"}
            buttonIcon={"fa-solid fa-square-phone-hangup"}
            buttonIconPosition={"left"}
            buttonClasses={
              "uppercase bg-red-700 text-sm hover:bg-red-600 text-white px-4 py-2 rounded"
            }
            buttonFunction={() => {
              isFullscreen && toggleFullscreen();
              setIsOpen(true);
            }}
          />
        )}
      </div>
      <LeaveModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={onConfirm}
        loading={loading}
      />
    </div>
  );
};

export default UserChatsHeader;

const LeaveModal = ({ isOpen, setIsOpen, onConfirm, loading }) => {
  return (
    <Modal isOpen={isOpen} titleShow={false} size={"sm"}>
      <div className="text-center my-6 space-y-8">
        <span className="my-2 text-6xl text-red-500 bg-slate-300 px-4 p-4 rounded-full">
          <i class="fa-solid fa-phone-xmark"></i>
        </span>
        <p className="mb-4">Are you sure you want to leave the call?</p>
      </div>
      <div className="flex justify-center gap-3">
        <Button
          buttonHasLink={true}
          buttonFunction={() => setIsOpen(false)}
          buttonLabel={"Cancel"}
          buttonIcon={"fa-light fa-xmark"}
          buttonIconPosition={"left"}
          buttonClasses={
            "uppercase !bg-red-500 text-sm hover:!bg-prontopsy-blue"
          }
        />
        <Button
          buttonLabel={loading ? "Leaving..." : "Leave"}
          buttonIcon={"fa-solid fa-right-from-bracket"}
          buttonIconPosition={"left"}
          buttonClasses={"uppercase text-sm hover:!bg-prontopsy-blue"}
          buttonFunction={onConfirm}
          loading={loading}
        />
      </div>
    </Modal>
  );
};
