import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import { useDispatch, useSelector } from "react-redux";
import { setTabSelection } from "../../store/slice/selectedTabSlice";

const Tabs = ({
  tabs,
  design,
  divClass,
  mainClasses,
  tabContentdivClasses,
  buttonClass,
  textClasses,
  loaded = true,
  tabBorder = false,
  activeTabItem,
  activeTabItemText,
  customDesign=false,
  setSelectedTabData = () => {},
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const selected = useSelector((state) => state?.selectedTabSlice?.data);
  let pageName = localStorage.getItem("pageName");
  const language = useSelector((state) => state?.languageSlice?.data);

  useEffect(() => {
    pageName && pageName == "faq" && dispatch(setTabSelection({}));
  }, [tabs]);
  useEffect(() => {
    if (list.length > 0) {
      let data = list.map((elm) => ({
        _id: elm?.categoryData?._id,
        title: elm?.categoryData?.title,
      }));
      const uniqueDataArray = [...new Set(data)];
      uniqueDataArray && setCategoryList(uniqueDataArray);
    }
  }, [list, tabs, language]);

  useEffect(() => {
    if (tabs && tabs.length > 0 && Object.keys(selected).length == 0) {
      setSelectedTab(tabs[0]);
      setSelectedTabData(tabs[0]);
    } else if (Object.keys(selected).length > 0) {
      setSelectedTab(selected);
      setSelectedTabData(selected);
    }
  }, [tabs, selected, language]);

  const handleTabSelection = (tab = {}) => {
    setTimeout(() => {
      let activeTab = [];
      let filteredTab = tabs.filter((tab) => activeTab.includes(tab._id))[0];
      if (filteredTab) {
        activeTab = [
          ...activeTab.filter((actab) => actab !== filteredTab._id),
          tab?._id,
        ];
      } else {
        activeTab = [...activeTab, tab?._id];
      }
      setSelectedTab(tab);
      setSelectedTabData(tab);
      dispatch(setTabSelection(tab));
    }, 100);
  };

  return (
    <div className={classNames("relative flex md:gap-10 gap-0", mainClasses)}>
      <div
        className={classNames(
          tabBorder ? "border-b border-slate-400 border-dashed pb-4" : ""
        )}
      >
        <nav
          className={classNames(
            "w-1/5 flex overflow-auto ",
            divClass,
            design === "style"
              ? "border-b border-slate-200 px-4 xl:px-8 gap-5 xl:gap-6"
              : "gap-3 xl:gap-4"
          )}
        >
          {tabs &&
            Array.isArray(tabs) &&
            tabs.length > 0 &&
            tabs.map((item, index) => (
              <button
                type="button"
                className={classNames(
                  "relative py-3 xl:py-4 rounded-full bg-transparent border border-black transition-all duration-200",
                  buttonClass,
                  design === "style"
                    ? "border-b-2 border-transparent px-0"
                    : "px-6",
                  item._id === selectedTab?._id
                    ? design === "style"
                      ? "border-prontopsy-pink"
                      : "border-prontopsy-pink bg-prontopsy-pink"
                    : design === "style"
                    ? "bg-prontopsy-pink"
                    : customDesign
                    ? "!bg-slate-200"
                    : "",
                  item._id === selectedTab?._id ? activeTabItem : ""
                )}
                
                key={index}
                onClick={() => handleTabSelection(item)}
              >
                {loaded ? (
                  <div
                    className={classNames(
                      "whitespace-nowrap line-clamp-3",
                      textClasses,
                      design === "style"
                        ? "text-sm font-semibold"
                        : "text-sm xl:text-base font-medium",
                      item._id === selectedTab?._id
                        ? design === "style"
                          ? "!text-carnation-500"
                          : "text-white"
                        : "text-prontopsy-blue" ,
                      item._id === selectedTab?._id ? activeTabItemText : " "
                    )}
                  >
                    {item.name}
                  </div>
                ) : (
                  <div role="status" className="animate-pulse">
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[640px] mb-2.5 mx-auto"></div>
                  </div>
                )}
              </button>
            ))}
        </nav>
      </div>
      <div
        className={classNames("w-4/5 relative bg-white", tabContentdivClasses)}
      >
        {selectedTab?.component}
      </div>
    </div>
  );
};

export default Tabs;
