import React, { useEffect, useState } from "react";
import PageBanner from "../components/sections/PageBanner";
import ImageBanner from "../assets/images/banner.webp";
// import Button from "../components/form/Button";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import PrivacyPolicy from "../components/sections/privacypolicy/PrivacyPolicy";
import Button from "../components/form/Button";
import Registeredpsychologist from "../components/sections/registeredpsychologist/Registeredpsychologist";
import { setTitle } from "../helpers/MetaTag";
import { useSelector } from "react-redux";

const TermsandConditions = () => {
  const [bannerInfo, setbannerInfo] = useState({});
  setTitle("Prontopsy |Terms-and-conditions");
  const data = useSelector((state) => state?.pageSlice?.data);
  const language = useSelector((state) => state?.languageSlice?.data);
  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en == "terms_and_conditions");
    setbannerInfo(info[0]);
  }, [data]);

  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
  };
  const termsData = {
    description:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.description?.[language],
  };

  return (
    <>
      <PageBanner bannerData={bannerData} />
      <PrivacyPolicy termsData={termsData} />
      <SmartPortal />
      <Register/>
      {/* <Registeredpsychologist /> */}
    </>
  );
};

export default TermsandConditions;
