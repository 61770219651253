import React, { useEffect, useState } from "react";
import Tabs from "../../components/elements/Tabs";
import Dettagli from "../../components/elements/tabContent/profile/appuntamentidetails/Dettagli";
import { useDispatch, useSelector } from "react-redux";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
import { appointmentDetails } from "../../service/appointmentService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { appointmentDetailsLang } from "../../components/elements/language";
import RatingStars from "../../components/elements/RatingStars";
import NoDataFound from "../../components/elements/NoDataFound";
import PaymentDetails from "../../components/elements/tabContent/profile/appuntamentidetails/PaymentDetails";
const AppointmentsDetails = () => {
  const [appointmentDetailsData, setAppointmentDetailsData] = useState({});
  const language = useSelector((state) => state.languageSlice.data);
  const [data, setData] = useState({});
  const [tabData, setTabData] = useState([]);
  const [selectedTabData, setSelectedTabData] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    dispatch(setTabSelection({}));
  }, []);

  useEffect(() => {
    appointmentDetails({ id }).then((res) => {
      if (res?.data?.status == 200) {
        setAppointmentDetailsData(res?.data?.docs);
      } else {
        toast.error("Error while fetching appointment details !");
      }
    });
  }, [id]);

  useEffect(() => {
    if (userDetails?.roleCode == "user") {
      setData(appointmentDetailsData?.consultantData);
    } else if (userDetails?.roleCode == "consultant") {
      setData(appointmentDetailsData?.clientData);
    } else {
      setData({});
    }
  }, [appointmentDetailsData]);

  const sections = [
    {
      title: {
        en: "Skill and Knowledge",
        es: "Habilidad y conocimiento",
        it: "Abilità e Conoscenza",
      },
      ratingData:
        appointmentDetailsData?.reviewData?.length > 0 &&
        appointmentDetailsData?.reviewData[0]?.knowledgeSkill,
    },
    {
      title: {
        en: "Cordiality and Communication",
        es: "Cordialidad y Comunicación",
        it: "Cordialità e Comunicazione",
      },
      ratingData:
        appointmentDetailsData?.reviewData?.length > 0 &&
        appointmentDetailsData?.reviewData[0]?.communication,
    },
    {
      title: {
        en: "Internet Access",
        es: "Acceso a Internet",
        it: "Accesso a Internet",
      },
      ratingData:
        appointmentDetailsData?.reviewData?.length > 0 &&
        appointmentDetailsData?.reviewData[0]?.internetAccess,
    },
  ].filter(Boolean);

  useEffect(() => {
    const tabData = [
      {
        _id: 1,
        name: `${appointmentDetailsLang?.appDetails?.[language]}`,
        component: (
          <Dettagli
            data={data}
            appointmentDetailsData={appointmentDetailsData}
          />
        ),
      },
      {
        _id: 2,
        name: `${appointmentDetailsLang?.appPayments?.[language]}`,
        component: (
          <PaymentDetails
            data={data}
            appointmentDetailsData={appointmentDetailsData}
          />
        ),
      },
      {
        _id: 3,
        name: `${appointmentDetailsLang?.appReviews?.[language]}`,
        component: (
          <>
            {appointmentDetailsData?.reviewData?.length > 0 && (
              <div className="grid grid-cols-3 gap-2 md:gap-5 lg:gap-10 border-t border-b border-neutral-300">
                {sections.map((section, index) => (
                  <div
                    key={index}
                    className={`flex flex-col items-center justify-center w-full py-6 space-y-2 ${
                      index % 2 === 1
                        ? " border-l border-r border-neutral-300"
                        : ""
                    }`}
                  >
                    <div className="max-w-32 m-auto w-full text-xs sm:text-base lg:text-lg font-RobotoSlab font-bold text-center text-prontopsy-blue">
                      {section?.title?.[language]}
                    </div>
                    <RatingStars data={section.ratingData} />
                  </div>
                ))}
              </div>
            )}
            <div className="text-black text-xl">
              {(appointmentDetailsData &&
                Array.isArray(appointmentDetailsData?.reviewData) &&
                appointmentDetailsData?.reviewData?.length > 0 &&
                appointmentDetailsData?.reviewData[0]?.review) ||
                ""}
            </div>
            {appointmentDetailsData?.reviewData?.length == 0 && <NoDataFound />}
          </>
        ),
      },
    ];
    setTabData(tabData);
  }, [language, data, appointmentDetailsData]);

  return (
    <section className="relative py-5">
      <div className="relative">
        <Tabs
          tabs={tabData}
          mainClasses={"flex-col"}
          divClass={
            "w-full flex-nowrap pb-5 border-b border-dashed border-neutral-300"
          }
          textClasses={
            "font-UnisonPro !font-bold italic !text-[11px] !leading-[15px] !whitespace-normal"
          }
          buttonClass={`max-w-48 xl:max-w-56 w-full !rounded-md !py-0 !px-5 h-14 flex items-center justify-center !max-w-72 !border-0 !bg-prontopsy-blue`}
          tabContentdivClasses={"w-full"}
          activeClass={"!px-10"}
          setSelectedTabData={setSelectedTabData}
          customDesign={true}
        />
      </div>
    </section>
  );
};

export default AppointmentsDetails;
