import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import Cards from "../../../payment/Cards";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  addStripeAccountService,
  deleteStripeAccountService,
} from "../../../../../service/stripeService";
import { toast } from "react-toastify";
const PaymentInfo = ({ data, setData = () => {} }) => {
  const [loaded, setLoaded] = useState(true);
  const [deleteLoaded, setDeleteLoaded] = useState(true);
  const location = useLocation();
  const language = useSelector((state) => state.languageSlice.data);
  // const user = JSON.parse(localStorage.getItem("userDetails"));
  const user = useSelector((state) => state?.profileSlice?.data);
  const code = new URLSearchParams(location.search).get("code");
  const state = new URLSearchParams(location.search).get("state");
  const clientId = "ca_HYNXq5R8FCunnbu3bg5dMmzY2km9GhHB";
  const titleLang = {
    en: "Payment Information",
    es: "Información del pago",
    it: "Informazioni sul pagamento",
  };
  const PaymentInfo = {
    title: titleLang?.[language],
    image: User,
  };

  // useEffect(() => {
  //   code &&
  //     addStripeAccountService({ accountCreationToken: code }).then((res) => {
  //     });
  // }, [code]);

  const handleRedirect = () => {
    if (user?.roleCode === "consultant") {
      const baseUrl = window.location.origin;

      const url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${baseUrl}/profile&client_id=${clientId}&state=${user?._id}&suggested_capabilities[]=card_payments&suggested_capabilities[]=transfers`;

      window.open(url, "_self");
    }
  };
  const handleDeleteAcc = () => {
    setDeleteLoaded(false);
    deleteStripeAccountService().then((res) => {
      if (res.data.status == 200) {
        setDeleteLoaded(true);
        toast.success(res?.data?.message);
      } else {
        toast.error("Something went wrong !");
      }
    });
  };
  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {PaymentInfo?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{ __html: PaymentInfo?.title }}
          ></h2>
        )}
      </div>
      
      <div className="flex  justify-between gap-5 py-5 border-t border-neutral-300">
      
        {user && user?.roleCode === "user" ? (
         
          <Cards isToggle={false} />
        ) : data?.stripeAccountId ? (
          <div className="w-full">
            <div className="grid gap-3 mb-4">
              <div className="relative">
                <div className="text-slate-800 font-normal">
                  STRIPE TEST BANK - 3456
                </div>
                <div className="text-slate-800 font-normal">
                  Your Stripe Account has already been connected successfully.
                </div>
              </div>
            </div>
            <Button
              buttonLabel={"Delete stripe account"}
              buttonLabelClasses={
                "!uppercase !text-base !whitespace-nowrap !font-medium"
              }
              buttonClasses={
                "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
              }
              buttonFunction={handleDeleteAcc}
              isDisable={!deleteLoaded}
            />
          </div>
        ) : (
          <div>
            <span className="text-sm text-gray-500">
              Please connect stripe.
            </span>
            <Button
              buttonLabel={"Create stripe account"}
              buttonLabelClasses={
                "!uppercase !text-base !whitespace-nowrap !font-medium"
              }
              buttonClasses={
                "!rounded-xl mt-4 !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
              }
              buttonFunction={handleRedirect}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentInfo;
