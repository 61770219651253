import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { apiRequest } from "../util/async/apiUtils";

const BlankLayout = () => {
  const navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("appointmentData"));
  const profile = JSON.parse(localStorage.getItem("userDetails"));
  const [isLoader, setIsLoader] = useState(false);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const checkData = async () => {
      setIsLoader(true);
      try {
        const res = await apiRequest(
          "post",
          "appointment/authenticate-appointment",
          {
            body: { appointmentId: data?.appointmentId },
          }
        );
        if (res?.status === 401) {
          localStorage.removeItem("appointmentData");
          navigate("/");
          setAuth(false);
        } else if (res?.status === 404) {
          localStorage.removeItem("appointmentData");
          navigate("/");
          setAuth(false);
        } else {
          if (res?.data?.data?.appointmentStatus === "close") {
            localStorage.removeItem("appointmentData");
            navigate("/");
            setAuth(false);
          } else {
            setIsLoader(false);
            setAuth(true);
          }
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        // Optionally handle different error statuses
        if (error?.response?.status === 401) {
          localStorage.removeItem("appointmentData");
          navigate("/");
          setAuth(false);
        } else {
          // Handle other types of errors (e.g., network issues)
          console.error("Unexpected error:", error);
          localStorage.removeItem("appointmentData");
          navigate("/");
          setAuth(false);
        }
      }
    };

    if (!data) {
      setIsLoader(false);
      navigate("/");
      localStorage.removeItem("appointmentData");
    } else {
      checkData();
    }
    setIsLoader(false);

    // if (!data && profile?.roleCode === "user") {
    //   navigate("/"); // Redirect if there's no appointment data
    //   return;
    // }
  }, [navigate, data]);

  return <>{auth && <Outlet />}</>;
};

export default BlankLayout;
