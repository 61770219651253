import React, { useEffect } from "react";
import Tabs from "../../components/elements/Tabs";
import AppuntamentiPassati from "../../components/elements/tabContent/profile/appuntamenti/AppuntamentiPassati";
import AppointmentInProgress from "../../components/elements/tabContent/profile/appuntamenti/AppointmentInProgress";
import UpcomingEvents from "../../components/elements/tabContent/profile/appuntamenti/UpcomingEvents";
import { useDispatch, useSelector } from "react-redux";
import { appointmentLang } from "../../components/elements/language";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
const Appointments = () => {
  const language = useSelector((state) => state.languageSlice.data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTabSelection({}));
  }, []);

  const tabData = [
    {
      _id: 1,
      name: appointmentLang?.pastAppointments?.[language],
      component: <AppuntamentiPassati />,
    },
    {
      _id: 2,
      name: appointmentLang?.appointmensInProgress?.[language],
      component: <AppointmentInProgress />,
    },
    {
      _id: 3,
      name: appointmentLang?.upcomingAppointments?.[language],
      component: <UpcomingEvents />,
    },
  ];

  return (
    <section className="relative py-5">
      <div className="relative">
        <Tabs
          tabs={tabData}
          tabBorder={true}
          mainClasses={"flex-col"}
          divClass={"w-full flex-nowrap py-2 !w-full"}
          textClasses={
            "font-UnisonPro !font-bold italic !text-[11px] !leading-[15px] !whitespace-normal !text-prontopsy-blue"
          }
          buttonClass={
            "max-w-48 xl:max-w-56 w-full !rounded-md !py-0 !px-5 h-14 flex items-center justify-center !border-none lg:!max-w-[30%] !text-prontpsy-blue"
          }
          tabContentdivClasses={"w-full"}
          activeTabItem={"!px-10 !bg-prontopsy-blue "}
          activeTabItemText={"!text-slate-100"}
        />
      </div>
    </section>
  );
};

export default Appointments;
