import React, { useState, useEffect } from "react";
import Image from "../../elements/Image";
import Loginimage from "../../../assets/images/loginimage.webp";
import InputGroup from "../../form/Input";
import Checkbox from "../../form/Checkbox";
import Button from "../../form/Button";
import {
  getProfileDetails,
  loginRequest,
  resetPasswordService,
  sendOtpService,
  verifyOtpService,
} from "../../../service/authService";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EmailModal from "../signup/EmailModal";
import OtpModal from "../signup/OtpModal";
import ResetPasswordModal from "../signup/ResetPasswordModal";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../../store/slice/authSlice";
import { setProfileData } from "../../../store/slice/profileSlice";
import { setInitialProfileData } from "../../../store/slice/initialProfileSlice";
import { useSocket } from "../../socket/SocketProvider";
import { setTokenData } from "../../../store/slice/tokenSlice";

const Login = () => {
  const [loaded, setLoaded] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [timeZoneData, setTimeZoneData] = useState({
    zoneName: "",
    gmtOffsetName: "",
  });
  const [signinData, setSigninData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [emailLoader, setEmailLoader] = useState(true);
  const [otp, setOtp] = useState("");
  const [emailForOtp, setEmailForOtp] = useState("");
  const [verifyOtpLoaded, setVerifyOtpLoaded] = useState(true);
  const [isResetModal, setIsResetModal] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState({});
  const [resetPasswordLoaded, setResetPasswordLoaded] = useState(true);
  const [detailsData, setDetailsData] = useState({});
  const socket = useSocket();

  //Get Local Time Zone
  useEffect(() => {
    // Get the local timezone name
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Get the current date
    const currentDate = new Date();

    // Get the GMT offset in minutes
    const offsetMinutes = currentDate.getTimezoneOffset();

    // Convert offset to hours and minutes
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemaining = Math.abs(offsetMinutes) % 60;

    // Construct the gmtOffsetName string
    const gmtOffsetName = `UTC${offsetMinutes <= 0 ? "+" : "-"}${String(
      offsetHours
    ).padStart(2, "0")}:${String(offsetMinutesRemaining).padStart(2, "0")}`;

    // Set the timezone data
    setTimeZoneData({
      zoneName: timeZoneName,
      gmtOffsetName: gmtOffsetName,
    });
  }, []);
  useEffect(() => {
    setSigninData((pre) => ({
      ...pre,
      timeZone: timeZoneData,
    }));
  }, [timeZoneData]);

  const dispatch = useDispatch();
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  let validate = isValidEmail(signinData?.email);

  const handleData = (e) => {
    setSigninData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setClicked(false);
  };

  console.log(socket, "bairee socket");

  const handleLogin = () => {
    setClicked(true);
    // !email && toast.error("Email is required!");
    // !password && toast.error("Password is required!");
    signinData.email &&
      signinData.password &&
      validate == true &&
      timeZoneData &&
      loginRequest({ ...signinData }, setLoaded).then((res) => {
        setClicked(false);
        if (res.data?.status == 200) {
          socket && socket.emit("userOnline", res?.data?.userData?._id);
          setDetailsData(res?.data?.userData);
          dispatch(setAuthData(res?.data));
          dispatch(setTokenData(res?.data?.accessToken));
          localStorage.setItem("auth_token", res?.data?.accessToken);
          localStorage.setItem("refreshToken", res?.data?.refreshToken);
          toast.success(res?.data?.message);
          getProfileDetails(res?.data?.accessToken).then((response) => {
            if (response?.data?.status === 200) {
              // console.log(socket, "socketsocket");
              // dispatch(setProfileData(response?.data?.docs));
              // setDetailsData(response?.data?.docs);
              dispatch(setInitialProfileData(response?.data?.docs));
              // navigate("/profile");
            }

            localStorage.setItem(
              "userDetails",
              JSON.stringify(response?.data?.docs)
            );
            // console.log(
            //   response?.data?.docs?.onlineStatus,
            //   "  response?.data?.docs?.onlineStatus  response?.data?.docs?.onlineStatus"
            // );
            // response?.data?.docs?.roleCode === "consultant" &&
            // response?.data?.docs?.onlineStatus === "offline" &&
          });
          setEmail("");
          setPassword("");
          navigate("/profile");
        } else {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    if (detailsData?._id) {
      socket && socket.emit("userOnline", detailsData?._id);
    }
  }, [detailsData]);

  const loginData = {
    image: Loginimage,
    title: "login",
  };

  const handleGoBack = () => {
    setIsModalOpen(true);
    setIsOtpModalOpen(false);
    setIsClicked(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <>
      <div className="relative py-10 md:py-12 lg:py-16 xl:py-20 ">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="block md:flex shadow-2xl">
            <div className="w-full md:w-1/2 p-10 hidden md:block">
              <div className="flex flex-col justify-center w-full aspect-[4/5] overflow-hidden">
                <Image
                  src={loginData?.image}
                  alt={""}
                  effect={"blur"}
                  className={"object-cover w-full h-full"}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="bg-prontopsy-yellow px-5 py-5 h-full flex flex-col items-center justify-center">
                <div
                  className="w-full mx-auto max-w-lg text-center space-y-4"
                  onKeyDown={handleKeyDown}
                >
                  {loginData?.title && (
                    <h2
                      className="!mb-6 sm:!mb-8 md:!mb-10 lg:!mb-12 xl:!mb-16 text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                      dangerouslySetInnerHTML={{ __html: loginData?.title }}
                    ></h2>
                  )}
                  <div className="relative">
                    <InputGroup
                      // onChange={(e) => setEmail(e.target.value)}
                      onChange={handleData}
                      inputName={"email"}
                      labelClasses={"font-RobotoSlab"}
                      inputPlaceholder={"Email"}
                      inputType={"email"}
                      inputClasses={
                        "!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                      }
                      inputValue={signinData.email}
                      errorType={
                        clicked == true &&
                        (!signinData.email
                          ? "warning"
                          : isValidEmail(signinData.email)
                          ? ""
                          : "danger")
                      }
                      errorText={
                        signinData.email && isValidEmail(signinData.email)
                          ? ""
                          : "Enter a valid email id!"
                      }
                    />
                  </div>
                  <div className="relative">
                    <InputGroup
                      // onChange={(e) => setPassword(e.target.value)}
                      onChange={handleData}
                      labelClasses={"font-RobotoSlab"}
                      inputPlaceholder={"Password"}
                      inputType={"password"}
                      inputName={"password"}
                      inputValue={signinData?.password}
                      divClass={"!border !border-black !bg-transparent"}
                      inputClasses={
                        " !text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                      }
                      errorType={
                        clicked == true && !signinData.password && "warning"
                        // (!signinData.password
                        //   ? "warning"
                        //   : isValidEmail(signinData.password)
                        //   ? ""
                        //   : "danger")
                      }
                      errorText={
                        signinData.password ? "" : "Enter correct password!"
                      }
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="relative">
                      <Checkbox
                        checkboxLabel={"Remember Me"}
                        labelClasses={"font-RobotoSlab"}
                        checkboxLabelClass={"!text-black !text-xs !capitalize"}
                      />
                    </div>
                    <div className="relative">
                      <Link
                        // to="/forgot-password"
                        onClick={toggleModal}
                        className="text-xs text-black font-RobotoSlab capitalize"
                      >
                        forgot password
                      </Link>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="relative">
                      <Button
                        buttonFunction={handleLogin}
                        buttonLabel={"login"}
                        buttonLabelClasses={"!capitalize font-RobotoSlab"}
                        buttonClasses={
                          "!h-10 lg:!h-12 !bg-prontopsy-blue !px-16"
                        }
                        isDisable={!loaded}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 py-5 border-t border-b border-neutral-400">
                    <div className="relative w-full flex justify-center">
                      <Button
                        buttonIcon={"fa-brands fa-facebook"}
                        buttonIconPosition={"left"}
                        buttonLabel={"continue with facebook"}
                        buttonLabelClasses={"!capitalize font-RobotoSlab"}
                        buttonClasses={"!h-10 lg:!h-12 !bg-prontopsy-lightblue"}
                        buttonHasLink={true}
                        buttonLink={"/"}
                      />
                    </div>
                    <div className="relative w-full flex justify-center">
                      <Button
                        buttonHasLink={true}
                        buttonLink={"/"}
                        buttonIcon={"fa-brands fa-google"}
                        buttonIconPosition={"left"}
                        buttonLabel={"continue with facebook"}
                        buttonLabelClasses={"!capitalize font-RobotoSlab"}
                        buttonClasses={
                          "!text-neutral-700 !h-10 lg:!h-12 !bg-white border border-black"
                        }
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="text-xs text-black font-RobotoSlab capitalize">
                      Dont have an account? 
                      <Link
                        to="/signup"
                        className="text-xs text-black font-RobotoSlab capitalize underline"
                      >
                        signup now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmailModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setIsClicked(false);
        }}
        isValidEmail={isValidEmail}
        onSubmit={(data) => {
          setIsClicked(true);
          if ((emailForOtp, isValidEmail(emailForOtp))) {
            sendOtpService({ email: emailForOtp }, setEmailLoader).then(
              (res) => {
                if (res.data.status == 200) {
                  toast.success(res.data?.message);
                  setIsModalOpen(false);
                  setIsOtpModalOpen(true);
                  // setEmailForOtp("");
                  setIsClicked(false);
                } else {
                  toast.error(res?.data?.message);
                }
              }
            );
          }
        }}
        email={emailForOtp}
        setEmail={setEmailForOtp}
        loaded={emailLoader}
        isClicked={isClicked}
        setIsClicked={setIsClicked}
      />
      <OtpModal
        isOpen={isOtpModalOpen}
        handleGoBack={handleGoBack}
        onClose={() => {
          setIsOtpModalOpen(false);
          setIsClicked(false);
        }}
        onSubmit={() => {
          if (otp && emailForOtp) {
            verifyOtpService(
              { emailOtp: otp, requestType: "forgot", email: emailForOtp },
              setVerifyOtpLoaded
            ).then((res) => {
              if (res.data.status === 200) {
                toast.success(res.data.message);
                setIsResetModal(true);
                // setOtp("");
                // setEmailForOtp("");
                setIsClicked(false);
              } else {
                toast.error(res.data?.message);
              }
            });
          } else if (!otp) {
            toast.warning("OTP is required!");
          } else if (!emailForOtp) {
            toast.warning("Email is missing!");
          }
        }}
        otp={otp}
        setOtp={setOtp}
        loaded={verifyOtpLoaded}
      />
      <ResetPasswordModal
        isOpen={isResetModal}
        onClose={() => {
          setIsResetModal(false);
          setIsClicked(false);
        }}
        handleGoBack={() => {
          setIsResetModal(false);
          setIsOtpModalOpen(true);
          setIsClicked(false);
        }}
        signupData={passwordInfo}
        setSignupData={setPasswordInfo}
        onSubmit={() => {
          setIsClicked(true);
          if (passwordInfo.newPassword && passwordInfo.confirmPassword) {
            resetPasswordService(
              { ...passwordInfo, email: emailForOtp, otp: otp },
              setResetPasswordLoaded
            ).then((res) => {
              if (res.data.status === 200) {
                toast.success(res.data.message);
                setIsResetModal(false);
                setIsOtpModalOpen(false);
                setIsClicked(false);
                setPasswordInfo({});
                setOtp("");
                setEmailForOtp("");
              } else {
                toast.error(res.data.message);
              }
            });
          }
        }}
        isClicked={isClicked}
        setIsClicked={setIsClicked}
        loaded={resetPasswordLoaded}
      />
    </>
  );
};

export default Login;
