import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import { useSocket } from "../socket/SocketProvider";
import { fetchProfileDetails } from "../../service/profileService";
import { useDispatch, useSelector } from "react-redux";
import { setConsultantSwitch } from "../../store/slice/switchSlice";
import usePageVisibility from "../../util/PageVisibility";

const busyLang = {
  en: "Busy",
  it: "Occupato",
  es: "Ocupado",
};
const onlineLang = {
  en: "Online",
  it: "In linea",
  es: "En línea",
};
const offlineLang = {
  en: "Offline",
  it: "Non in linea",
  es: "Desconectado",
};
const setOnlineLang = {
  en: "Set Online",
  it: "Imposta in linea",
  es: "Establecer en línea",
};
const setOfflineLang = {
  en: "Set Offline",
  it: "Imposta offline",
  es: "Establecer sin conexión",
};

const ProfileOnlineButton = ({ data, id, socket }) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  const [status, setStatus] = useState("offline");
  const [profileData, setProfileData] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const token = localStorage.getItem("auth_token");
  const dispatch = useDispatch();

  // const socket = useSocket();
  const label1 =
    status == "busy"
      ? busyLang?.[language]
      : status == "online"
      ? onlineLang?.[language]
      : offlineLang?.[language];
  const label2 =
    status == "online"
      ? setOfflineLang?.[language]
      : status == "offline"
      ? setOnlineLang?.[language]
      : "";
  const textColor = {
    online: "text-prontopsy-green",
    busy: "text-prontopsy-yellow",
    offline: "text-prontopsy-red",
    "": "text-neutral-400",
  };
  const bgColor = {
    online: "bg-prontopsy-green",
    busy: "bg-prontopsy-yellow",
    offline: "bg-prontopsy-red",
    "": "bg-neutral-200",
  };

  useEffect(() => {
    id &&
      fetchProfileDetails(id, setProfileData, setLoaded).then((res) => {
        if (res.status === 200) {
          setStatus(res?.docs?.onlineStatus);
        }
      });
  }, [id]);

  useSocket(
    "userStatusResponse",
    (info) => {
      if (info && info.userId && info?.userId === id) {
        setStatus(info?.online ? "online" : "offline");
      }
      // setStatus()
      setTimeout(() => {
        id &&
          fetchProfileDetails(id, setProfileData, setLoaded).then((res) => {
            if (res.status === 200) {
              setStatus(res?.docs?.onlineStatus);
            }
          });
      }, 1000);
    },
    [socket, id]
  );

  // useEffect(() => {
  //   profileData && setStatus(profileData?.onlineStatus);
  // }, [profileData, id]);

  useSocket(
    "consultantBusyResponse",
    (data) => {
      if (data && data?.consultantId && data?.consultantId == id) {
        data?.isBusy ? setStatus("busy") : setStatus("online");
        // data?.isBusy && setStatus("busy");
      }
      // setTimeout(() => {
      //   id &&
      //     fetchProfileDetails(id, setProfileData, setLoaded).then((res) => {
      //       if (res.status === 200) {
      //         setStatus(res?.docs?.onlineStatus);
      //       }
      //     });
      // }, 1000);
    },
    [socket]
  );
  useSocket("consultantJoinResponse", async (data) => {
    setTimeout(() => {
      id &&
        fetchProfileDetails(id, setProfileData, setLoaded).then((res) => {
          if (res.status === 200) {
            setStatus(res?.docs?.onlineStatus);
          }
        });
    }, 1000);
  });
  useSocket(
    "chatJoinConfirmResponse",
    async (data) => {
      setTimeout(() => {
        id &&
          fetchProfileDetails(id, setProfileData, setLoaded).then((res) => {
            if (res.status === 200) {
              setStatus(res?.docs?.onlineStatus);
            }
          });
      }, 1000);
    },
    []
  );
  const handleStatus = () => {
    !socket && window.location.reload();

    if (socket && status == "online") {
      socket.emit("userOffline", id);
      setStatus("offline");
      localStorage.removeItem("appointmentData");
    } else if (socket && status == "offline") {
      socket.emit("userOnline", id);
      setStatus("online");
    }
    dispatch(setConsultantSwitch({ status: true }));
  };
  // usePageVisibility(token, socket, profileData?._id);

  return (
    <>
      <div
        className={classNames(
          "max-w-[200px] m-auto rounded-xl py-2 px-2 cursor-pointer",
          bgColor[status]
        )}
        onClick={handleStatus}
      >
        <div
          className={classNames(
            "text-center font-UnisonPro text-white text-[20px] italic font-bold",
            textColor[status]
          )}
        >
          {label1}
        </div>
        <div
          className={classNames(
            "text-center text-white text-[12px] italic font-bold uppercase",
            textColor[status]
          )}
        >
          {label2}
        </div>
      </div>
    </>
  );
};

export default ProfileOnlineButton;
