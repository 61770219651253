import React, { useEffect, useState } from "react";
import Image from "../elements/Image";
import Rating from "../elements/Rating";
import OnlineButton from "../elements/OnlineButton";
import { classNames } from "../../helpers/classNames";
import { getFlag } from "../../util/Flag";
import Button from "../form/Button";
import Favorite from "../form/Favorite";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { consultantDetails } from "../elements/language";
import AppointmentModal from "../elements/AppointmentModal";
import CallingModal from "../elements/CallingModal";
import { emailChatInitialization } from "../../service/emailService";
import { useSocket } from "../socket/SocketProvider";

const ConsultantItem = ({
  data,
  mainClasses,
  divClasses,
  topClasses,
  bottomClasses,
  nameClasses,
  hasLink = false,
  isConsultant = false,
  isOnlineButton = true,
  rateClass,
  subheadingClass,
  isShowDescription = true,
  callUsBtn = false,
}) => {
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const authToken = localStorage.getItem("auth_token");
  const language = useSelector((state) => state?.languageSlice?.data);
  const [isOpen, setIsOpen] = useState(false);
  const [isCallingModalOpen, setIsCallingModalOpen] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [modeData, setmodeData] = useState([]);
  const [chatId, setChatId] = useState(null);
  const socket = useSocket();

  const color = {
    online: "bg-prontopsy-green",
    busy: "bg-prontopsy-yellow",
    offline: "bg-prontopsy-red",
    "": "bg-neutral-200",
  };
  const navigate = useNavigate();

  const handleEmailChat = () => {
    emailChatInitialization({ userId: data?._id }).then((res) => {
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        setChatId(res.data?.data?._id);
        navigate(
          `/email-details/${res.data?.data?._id}?consultantId=${data._id}`
        );
      }
    });
  };

  return (
    <>
      <div
        className={classNames(
          "relative w-full flex py-5 px-[2%] md:px-[8%] lg:px-[12%]",
          mainClasses
        )}
      >
        <div
          className={classNames(
            "relative w-full block xl:flex space-y-4 space-x-10 bg-white rounded-6xl border border-slate-400 shadow-md",
            divClasses,
            isConsultant
              ? "gap-2 px-5 pt-6 pb-10"
              : " gap-10 px-6 lg:px-8 py-12"
          )}
        >
          <div
            className={classNames(
              "lg:w-36 xl:w-40 flex-shrink-0 flex flex-col items-center space-y-4 mx-auto",
              topClasses
            )}
          >
            <div className="relative w-24 lg:w-28 xl:w-36 h-24 lg:h-28 xl:h-36 flex-shrink-0 rounded-full">
              {data?.image && (
                <div
                  className="w-full h-full overflow-hidden rounded-full cursor-pointer"
                  onClick={() => {
                    if (!hasLink || !authToken) {
                      navigate(`/public-front-consultant-profile/${data._id}`);
                    }
                  }}
                >
                  <Image
                    src={data?.image}
                    alt={""}
                    effect={"blur"}
                    className={"object-contain"}
                  />
                </div>
              )}
              {data?.isOnline && (
                <div
                  className={classNames(
                    "absolute bottom-1 right-1 w-4 lg:w-6 xl:w-8 aspect-square rounded-full border-2 border-white",
                    color[data?.isOnline]
                  )}
                ></div>
              )}
            </div>
            {data?.rating && <Rating data={data?.rating || 0} />}
            {data?.isOnline && isOnlineButton && (
              <div className="!mt-8">
                <OnlineButton data={data?.isOnline} />
              </div>
            )}
            {data?.rate?.currency && data?.rate?.value && (
              <div
                className={classNames(
                  "",
                  rateClass,
                  isConsultant ? "mt-2 hidden" : "mt-16 block"
                )}
              >
                <div className="text-prontopsy-lightblue font-light text-base">
                  {data?.rate?.currency} {data?.rate?.value} / min
                </div>
              </div>
            )}
          </div>
          <div
            className={classNames(
              " w-full flex-shrink space-y-5 !ml-0 flex flex-col",
              bottomClasses,
              isConsultant ? "!mt-0" : ""
            )}
          >
            <div
              className={classNames(
                "space-y-2",
                isConsultant ? "pr-0 text-center" : "text-left md:pr-10"
              )}
            >
              <h2
                className={classNames(
                  " leading-[1.4rem] font-UnisonPro italic text-prontopsy-blue font-bold cursor-pointer",
                  nameClasses,
                  isConsultant
                    ? "text-[16px] leading-[22px]"
                    : "text-lg lg:text-xl xl:text-[24px]"
                )}
                onClick={() => {
                  if (!hasLink || !authToken) {
                    navigate(`/public-front-consultant-profile/${data._id}`);
                  }
                }}
              >
                {data?.name == null ? "Hello" : data?.name}
              </h2>
              {data?.intervention && (
                <h4
                  className={classNames(
                    " font-medium text-black cursor-pointer leading-[22px] !mt-3",
                    isConsultant
                      ? "text-[16px] leading-[22px]"
                      : "text-base lg:text-lg xl:text-[22px]"
                  )}
                  onClick={() => {
                    if (!hasLink || !authToken) {
                      navigate(`/public-front-consultant-profile/${data._id}`);
                    }
                  }}
                >
                  {data?.intervention}
                </h4>
              )}
              {data?.specialization && (
                <h6
                  onClick={() => {
                    if (!hasLink || !authToken) {
                      navigate(`/public-front-consultant-profile/${data._id}`);
                    }
                  }}
                  className={classNames(
                    "text-sm lg:text-[16px] leading-[22px] text-neutral-700 uppercase cursor-pointer pt-2",
                    subheadingClass
                  )}
                >
                  {data?.specialization}
                </h6>
              )}

              <div
                className={classNames(
                  "flex items-center space-x-8 !my-4",
                  isConsultant ? "justify-center" : ""
                )}
              >
                {data?.nationality && (
                  <div className="flex items-center space-x-2">
                    <div className="w-5 aspect-[4/3] overflow-hidden flex-shrink-0 flex">
                      <Image
                        src={getFlag(data?.nationality?.code)}
                        alt={""}
                        effect={"blur"}
                        className={"object-contain"}
                      />
                    </div>
                    <div className="text-sm !leading-none text-neutral-700">
                      {data?.nationality?.name}
                    </div>
                  </div>
                )}
                {data?.language && (
                  <div className="flex items-center space-x-2 text-xl !leading-none text-prontopsy-blue">
                    <i className="fa-regular fa-fw fa-language"></i>
                    <div className="text-sm !leading-none text-neutral-700">
                      {<span>{data?.language?.name}</span>}
                    </div>
                  </div>
                )}
              </div>
              {data?.rate?.currency && data?.rate?.value && (
                <div
                  className={classNames(
                    "",
                    isConsultant ? "mt-2 block" : "!mt-20 hidden"
                  )}
                >
                  <div className="text-prontopsy-lightblue font-bold text-lg mt-8">
                    {data?.rate?.currency} {data?.rate?.value} / min
                  </div>
                </div>
              )}
            </div>
            {data?.bio && isShowDescription && (
              <div className="relative w-full md:px-6 px-2 py-5 border border-neutral-200 rounded-2xl !mb-5">
                <div
                  className="text-sm text-neutral-400 space-y-3 !leading-normal line-clamp-5"
                  dangerouslySetInnerHTML={{ __html: data?.bio }}
                ></div>
              </div>
            )}
            {data?.actions && roleCode !== "consultant" && (
              <div className="relative space-y-4 !mt-auto">
                {data &&
                  Array.isArray(data?.serviceDataTableMap) &&
                  data?.serviceDataTableMap.length > 0 &&
                  data?.serviceDataTableMap.map((elm, index) => (
                    <div key={index}>
                      {data?.isOnline === "online" &&
                        elm?.service?.slug ===
                          "direct-access-online-psychological-counseling" && (
                          <div className="flex justify-center" key={index}>
                            <Button
                              buttonClasses={classNames(
                                " !w-full !max-w-md !rounded-lg !bg-prontopsy-green hover:!bg-prontopsy-blue",
                                callUsBtn ? "h-9" : " lg:!h-12"
                              )}
                              buttonHasLink={data?.actions?.call?.isLink}
                              buttonFunction={() => {
                                !socket && window.location.reload();
                                if (roleCode) {
                                  // data?.actions?.call?.click(); // Invoke the click function
                                  setIsCallingModalOpen(!isCallingModalOpen);
                                  setSelectedServiceId(elm?.service?._id);
                                  setmodeData(elm?.mode);
                                } else {
                                  navigate("/signin"); // Navigate to the signin page
                                }
                              }}
                              buttonLabel={
                                consultantDetails?.callNow?.[language]
                              }
                              buttonLabelClasses={"uppercase !text-base"}
                            />
                          </div>
                        )}
                      {elm?.service?.slug ===
                        "scheduled-online-psychological-counselling" && (
                        <div className="flex justify-center">
                          <div>
                            <Button
                              buttonClasses={
                                "w-full !rounded-md !bg-prontopsy-pink hover:!bg-prontopsy-blue !text-[15px] !px-12 !h-8"
                              }
                              buttonHasLink={data?.actions?.booking?.isLink}
                              // buttonFunction={data?.actions?.booking?.click}
                              buttonFunction={() => {
                                if (roleCode) {
                                  // data?.actions?.booking?.click(); // Invoke the click function
                                  setIsOpen(!isOpen);
                                  setSelectedServiceId(elm?.service?._id);
                                  setmodeData(elm?.mode);
                                } else {
                                  navigate("/signin"); // Navigate to the signin page
                                }
                              }}
                              buttonLabel={
                                consultantDetails?.bookConsult?.[language]
                              }
                              buttonLabelClasses={"uppercase"}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                {data?.actions?.email && (
                  //  && data?.isOnline === "online"
                  <div className="flex justify-center !mt-2">
                    <Button
                      buttonClasses={
                        " !rounded-md !bg-transparent !text-prontopsy-lightblue hover:!text-prontopsy-pink !text-[12px] !font-normal"
                      }
                      buttonHasLink={data?.actions?.email?.isLink}
                      buttonLink={
                        roleCode ? data?.actions?.email?.link : "/signin"
                      }
                      // buttonTarget={roleCode}
                      buttonFunction={handleEmailChat}
                      buttonLabel={consultantDetails?.emailMe?.[language]}
                      buttonLabelClasses={"uppercase !font-normal"}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="absolute z-[1] top-8 right-8 !m-0">
              {((roleCode && roleCode !== "consultant") || !authToken) &&
                data && (
                  <Favorite isChecked={data?.isFavorite} id={data?._id} />
                )}
            </div>
          </div>
        </div>
      </div>

      <AppointmentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={data}
        serviceId={selectedServiceId}
        setServiceId={setSelectedServiceId}
        modes={modeData}
      />
      <CallingModal
        isOpen={isCallingModalOpen}
        setIsOpen={setIsCallingModalOpen}
        data={data}
        serviceId={selectedServiceId}
        setServiceId={setSelectedServiceId}
        modes={modeData}
      />
    </>
  );
};

export default ConsultantItem;
