import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/sections/PageBanner";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import Consultants from "../components/sections/Consultants";
import ImageBanner from "../assets/images/FindPsychologist.webp";
import { useSelector } from "react-redux";
import PsychologistExpertSearch from "../components/sections/FindPsychologist/PsychologistExpertSearch";
import ConsultantCard from "../components/sections/consultantDetails/ConsultantCard";

const FindPsychologist = () => {
  const [bannerInfo, setbannerInfo] = useState({});
  setTitle("Prontopsy | Find A Psychologist");
  const data = useSelector((state) => state?.pageSlice?.data);
  const language = useSelector((state) => state?.languageSlice?.data);

  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en == "Find_psychologist");
    setbannerInfo(info[0]);
  }, [data]);

  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
    subTitle: bannerInfo?.subTitle,
  };
  // const bannerData = {
  //   image: ImageBanner,
  //   title: "our expert <span class='text-prontopsy-pink'>Psychologists</span>",
  // };

  return (
    <>
      <PageBanner bannerData={bannerData} />
      <PsychologistExpertSearch
        title={bannerData?.subTitle}
        language={language}
      />

      <SmartPortal />
      {/* <ConsultantCard/> */}
      <Register />
    </>
  );
};

export default FindPsychologist;
