import React, { useEffect, useRef, useState } from "react";
import RatingStars from "../../components/elements/RatingStars";
import Image from "../../components/elements/Image";
import User from "../../assets/images/user.svg";
import { useDispatch, useSelector } from "react-redux";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
import {
  fetchAvgStatReview,
  fetchReviewList,
} from "../../service/reviewService";
import dayjs from "dayjs";
import SpinLoader from "../../components/elements/table/SpinLoader";

const Reviews = () => {
  const [loaded, setLoaded] = useState(true);
  const token = localStorage.getItem("auth_token");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [avgReviews, setAvgReviews] = useState({});
  const language = useSelector((state) => state?.languageSlice?.data);
  // for pagination
  const [limit, setLimit] = useState(4);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);

  const reviews = {
    en: "Reviews",
    es: "Reseñas",
    it: "Recensioni",
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);

  useEffect(() => {
    fetchReviewList(
      user?._id,
      { limit: limit, page: page, sortQuery: "-createdAt" },
      token,
      setList,
      setLoaded,
      setHasNextPage
    ).then((res) => {
      if (res?.data?.docs?.status === 200) {
        let metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        page !== 1
          ? setAllList((prevList) => [...prevList, ...res?.data?.docs?.data])
          : setAllList(res?.data?.docs?.data);
      }
    });
  }, [page]);

  useEffect(() => {
    if (scrolledToBottom && hasNextPage && loaded) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage, loaded]);

  useEffect(() => {
    fetchAvgStatReview(user?._id, token, setAvgReviews);
  }, [token]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTabSelection({}));
  }, [dispatch]);

  const sections = [
    {
      title: {
        en: "Skill and Knowledge",
        es: "Habilidad y conocimiento",
        it: "Abilità e Conoscenza",
      },
      ratingData: avgReviews && avgReviews?.data?.avgKnowledgeSkill,
    },
    {
      title: {
        en: "Cordiality and Communication",
        es: "Cordialidad y Comunicación",
        it: "Cordialità e Comunicazione",
      },
      ratingData: avgReviews && avgReviews?.data?.avgCommunication,
    },
    {
      title: {
        en: "Internet Access",
        es: "Acceso a Internet",
        it: "Accesso a Internet",
      },
      ratingData: avgReviews && avgReviews?.data?.avgInternetAccess,
    },
  ];

  const reviewsData =
    allList.length > 0 &&
    allList.map((elm) => ({
      id: elm?._id,
      image: elm?.consultantData?.image?.url || User,
      user:
        elm?.consultantData?.firstName + " " + elm?.consultantData?.lastName,
      rating: elm?.rating,
      review: elm?.review,
      date: dayjs(elm?.createdAt).format("ddd, MMM D, YYYY h:mm A"),
    }));

  return (
    <div className="relative">
      {allList.length === 0 && !loaded ? (
        <SpinLoader />
      ) : (
        <div>
          <h5 className="text-lg text-prontopsy-blue font-RobotoSlab font-bold py-2">
            {reviews?.[language]}
          </h5>
          <div className="grid md:grid-cols-3 grid-cols-2 gap-2 md:gap-5 lg:gap-10 border-t border-b border-neutral-300 md:py-0 py-3">
            {Object.keys(avgReviews).length > 0 &&
              sections.map((section, index) => (
                <div
                  key={index}
                  className={`flex flex-col items-center justify-center w-full md:py-6 py-2 space-y-2 ${
                    index % 2 === 1
                      ? " md:border-l md:border-r md:border-neutral-300"
                      : " "
                  }`}
                >
                  <div className="md:max-w-48 max-w-36 text-xs sm:text-base lg:text-lg font-RobotoSlab font-bold text-center text-prontopsy-blue m-auto">
                    {section?.title?.[language]}
                  </div>
                  <RatingStars data={section.ratingData} />
                </div>
              ))}
          </div>

          {/* Review list section */}
          <div
            ref={scrollableRef}
            className="grid grid-cols-1 gap-0 divide-y divide-neutral-300 h-[75vh] overflow-auto shadow-md p-2"
          >
            {reviewsData &&
              reviewsData.length > 0 &&
              reviewsData.map((review, index) => (
                <div
                  key={index}
                  className="flex items-center gap-6 py-4 sm:py-6 lg:py-10"
                >
                  <div className="w-20 h-20 rounded-full overflow-hidden border border-neutral-300 p-2 flex-shrink-0">
                    <div className="w-full h-full">
                      <Image
                        src={review.image}
                        alt={""}
                        effect={"blur"}
                        className={"object-cover w-full h-full"}
                      />
                    </div>
                  </div>
                  <div className="w-[calc(100%-80px)] flex-shrink">
                    <div className="font-RobotoSlab text-xs sm:text-base lg:text-lg text-prontopsy-blue capitalize font-bold">
                      {review.user}
                    </div>
                    <RatingStars data={review.rating} />
                    <div
                      className="text-neutral-800 text-xs sm:text-base lg:text-lg font-RobotoSlab capitalize pt-2"
                      dangerouslySetInnerHTML={{ __html: review.review }}
                    ></div>

                    <div className="text-neutral-500 text-xs sm:text-base lg:text-lg font-RobotoSlab">
                      {review.date}
                    </div>
                  </div>
                </div>
              ))}
            {!loaded && (
              <div className="flex justify-center mt-4">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Reviews;
