import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const authToken = localStorage.getItem("auth_token");
  const authData = useSelector((state) => state.tokenSlice?.data);

  const SOCKET_URL = "https://dev.api.prontopsy.com";

  const connectSocket = () => {
    if ((authToken && !socket) || Object.keys(authData).length > 0) {
      // Ensure we don't create multiple connections
      const newSocket = io(SOCKET_URL, {
        auth: {
          token: authToken || authData,
        },
      });
      setSocket(newSocket);

      newSocket.on("connect", () => {
        console.log("Socket connected");
      });
      // console.log("mmmmm");
      newSocket.on("disconnect", () => {
        console.log("Socket disconnected");
        setSocket(null);
      });
    } else {
      const newSocket = io(SOCKET_URL);
      setSocket(newSocket);
      // console.log("first");
      newSocket.on("connect", () => {
        console.log("Socket connected");
      });

      newSocket.on("disconnect", () => {
        console.log("Socket disconnected");
        setSocket(null);
      });
    }
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  };

  const reconnectSocket = () => {
    disconnectSocket();
    connectSocket();
  };

  useEffect(() => {
    if ((!socket && authToken) || authData) {
      connectSocket();
    }

    // Cleanup on unmount
    // return () => {
    //   disconnectSocket();
    // };
  }, [authToken, authData]); // Only depend on authToken to manage socket connection

  return (
    <SocketContext.Provider
      value={{ socket, reconnectSocket, disconnectSocket }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (eventName, callback, dependencies = []) => {
  const { socket } = useContext(SocketContext);
  const memoizedCallback = useCallback(callback, dependencies);

  useEffect(() => {
    if (!socket) return;

    socket.on(eventName, memoizedCallback);

    return () => {
      socket.off(eventName, memoizedCallback);
    };
  }, [socket, eventName, memoizedCallback]);

  return socket;
};

export default SocketContext;
