export const footerLang = {
  what: {
    en: "What Is Prontopsy?",
    es: "¿Qué es la prontopsia?",
    it: "Cos'è la prontopsia?",
  },
  faq: {
    en: "Faq's",
    es: "Preguntas Frecuentes",
    it: "Domande Frequenti",
  },
  privacy: {
    en: "Privacy Policies",
    es: "Políticas de privacidad",
    it: "Politiche Sulla Privacy",
  },
  about: {
    en: "About Us",
    es: "Sobre Nosotras",
    it: "Politiche Sulla Privacy",
  },
  terms: {
    en: "Terms Of Services",
    es: "Términos de servicios",
    it: "Termini di servizio",
  },
  contact: {
    en: "Contact Us",
    es: "Contacta con nosotras",
    it: "Contattaci",
  },
};
export const sideBar = {
  Home_Label: {
    en: "Home",
    es: "Hogar",
    it: "Casa",
  },
  Find_a_Psychologist_Label: {
    en: "Find a Psychologist",
    es: "Encontrar un psicólogo",
    it: "Trova uno psicologo",
  },
  How_It_Works_Label: {
    en: "How It Works",
    es: "Cómo funciona",
    it: "Come funziona",
  },
  Community_Psychology_Label: {
    en: "Community Psychology",
    es: "Psicología Comunitaria",
    it: "Psicologia di comunità",
  },
  Area_of_Intervention_Label: {
    en: "Area of Intervention",
    es: "Área de Intervención",
    it: "Area di intervento",
  },
  About_us_Label: {
    en: "About us",
    es: "Sobre nosotros",
    it: "Chi siamo",
  },
  Frequently_Asked_Questions_Label: {
    en: "Frequently Asked Questions",
    es: "Preguntas frecuentes",
    it: "Domande frequenti",
  },
  Blog_Label: {
    en: "Blog",
    es: "Blog",
    it: "Blog",
  },
  Leave_a_Feedback_Label: {
    en: "Leave a Feedback",
    es: "Deja un comentario",
    it: "Lascia un feedback",
  },
  Book_an_Interview_dial_Label: {
    en: "Book an Interview dial",
    es: "Reserve una entrevista marcar",
    it: "Prenota un quadrante per un'intervista",
  },
  Public_Front_Consultant_profile_Label: {
    en: "Public Front Consultant profile",
    es: "Perfil de Consultor Frente Público",
    it: "Profilo del Consulente del Fronte Pubblico",
  },
  Contact_us_Label: {
    en: "Contact us",
    es: "Contacta con nosotros",
    it: "Contattaci",
  },
  wishList: {
    en: "Wish List",
    es: "Lista de deseos",
    it: "Lista dei desideri",
  },
};
export const profileSideBar = {
  controlPanel: {
    en: "Control Panel",
    es: "Panel De Control",
    it: "Pannello Di Controllo",
  },
  myProfile: {
    en: "My Profile",
    es: "Mi Perfil",
    it: "Il mio profilo",
  },
  viewOnline: {
    en: "View Online",
    es: "Ver online",
    it: "Visualizza in linea",
  },
  appointments: {
    en: "Appointments",
    es: "Equipo",
    it: "Appuntamenti",
  },
  myClients: {
    en: "My Clients",
    es: "Mis Clientes",
    it: "I Miei Clienti",
  },
  email: {
    en: "E-mail",
    es: "E-mail",
    it: "E-mail",
  },
  myReviews: {
    en: "My reviews",
    es: "Mis reseñas",
    it: "Le mie recensioni",
  },
  logOut: {
    en: "Log Out",
    es: "Cerrar Sesión",
    it: "Disconnettersi",
  },
  wishList: {
    en: "Wish List",
    es: "Lista de deseos",
    it: "Lista dei desideri",
  },
};
export const consultantDetails = {
  biography: {
    en: "Biography",
    es: "Biografía",
    it: "Biografia",
  },
  spelizationArea: {
    en: "Specialization Area",
    es: "Área de Especialización",
    it: "Area di specializzazione",
  },
  areaOfIntervention: {
    en: "Area Of Intervention",
    es: "Área de Intervención",
    it: "Area di Intervento",
  },
  mentalHealth: {
    en: "Mental Health",
    es: "Salud Mental",
    it: "Salute Mentale",
  },
  psyChologicalProblems: {
    en: "Psychological Problems",
    es: "Problemas Psicológicos",
    it: "Problemi Psicologici",
  },
  theraputicProblems: {
    en: "Therapeutic Problems",
    es: "Problemas Terapéuticos",
    it: "Problemi Terapeutici",
  },
  otherTopics: {
    en: "Other Topics",
    es: "Otros Temas",
    it: "Altri Argomenti",
  },
  serviceOffered: {
    en: "Service Offered",
    es: "Servicios Ofrecidos",
    it: "Servizio offerto",
  },
  review: {
    en: "Reviews",
    es: "Reseñas",
    it: "Recensioni",
  },
  callNow: {
    en: "Call Now",
    es: "Llamar Ahora",
    it: "Chiama Ora",
  },
  bookConsult: {
    en: "Book A Consult",
    es: "Reserva una Consulta",
    it: "Prenota una Consultazione",
  },
  emailMe: {
    en: "Email Me",
    es: "Envíame un correo electrónico",
    it: "Invia un'email",
  },
  serviceName: {
    en: "Service Name",
    es: "Nombre del Servicio",
    it: "Nome del Servizio",
  },
  cost: {
    en: "Cost",
    es: "Costo",
    it: "Costo",
  },
  mode: {
    en: "Mode",
    es: "Modo",
    it: "Modo",
  },
  duration: {
    en: "Duration",
    es: "Duración",
    it: "Durata",
  },
  showLess: {
    en: "Show Less",
    es: "Mostrar menos",
    it: "Mostra meno",
  },
  showMore: {
    en: "Show More",
    es: "Mostrar más",
    it: "Mostra più",
  },
  summery: {
    en: "Summery",
    es: "Resumen",
    it: "Sommaria",
  },
  clientFeedback: {
    en: "Client Feedback",
    es: "Comentarios de los clientes",
    it: "Feedback dei clienti",
  },
};
export const submitBtn = {
  en: "Submit",
  es: "Entregar",
  it: "Invia",
};
export const saveChange = {
  en: "Save Changes",
  es: "Guardar Cambios",
  it: "Salva Modifiche",
};
export const readmoreBtn = {
  en: "Read More",
  es: "Leer más",
  it: "Per saperne di più",
};
export const detailsBtn = {
  en: "Details",
  es: "Detalles",
  it: "Dettagli",
};
export const viewallBtn = {
  en: "View All",
  es: "Ver Todo",
  it: "Mostra Tutto",
};
export const addBtn = {
  en: "Add",
  es: "Agregar",
  it: "Aggiungi",
};
export const deleteBtn = {
  en: "Delete",
  es: "Eliminar",
  it: "Elimina",
};
export const removeBtn = {
  en: "Remove",
  es: "Eliminar",
  it: "Elimina",
};
export const profile = {
  personalInformation: {
    en: "Personal Information",
    es: "Información Personal",
    it: "Informazioni Personali",
  },
  membershipInformation: {
    en: "Membership Information",
    es: "Información de Membresía",
    it: "Informazioni sulla Membresia",
  },
  billingInformation: {
    en: "Billing Information",
    es: "Información de Facturación",
    it: "Informazioni di Fatturazione",
  },
  paymentInformation: {
    en: "Payment Information",
    es: "Información de Pago",
    it: "Informazioni Sul Pagamento",
  },
  accountInformation: {
    en: "Account Information",
    es: "Información de la Cuenta",
    it: "Informazioni dell'Account",
  },
  memberInformation: {
    en: "Member Information",
    es: "Información del Miembro",
    it: "Informazioni del Membro",
  },
  insuranceInformation: {
    en: "Insurance Information",
    es: "Información de Seguro",
    it: "Informazioni Assicurative",
  },
  biography: {
    en: "Biography",
    es: "Biografía",
    it: "Biografia",
  },
  specialiazion: {
    en: "Information on Intervention and Specialization",
    es: "Información sobre Intervención y Especialización",
    it: "Informazioni su Intervento E Specializzazione",
  },
  languageInformation: {
    en: "Language Information",
    es: "Información de idioma",
    it: "Informazioni Sulla Lingua",
  },
  serviceOffered: {
    en: "Service Offered",
    es: "Servicio ofrecido",
    it: "Servizio Offerto",
  },
  dailySchedule: {
    en: "Daily Schedule",
    es: "Horario Diario",
    it: "Programma giornaliero",
  },
};
export const commonFormLabels = {
  firstName: {
    en: "First Name",
    es: "Nombre De Pila",
    it: "Nome",
  },
  lastName: {
    en: "Last Name",
    es: "Apellido",
    it: "Cognome",
  },
  name: {
    en: "Name",
    es: "Nombre",
    it: "Nome",
  },
  gender: {
    en: "Gender",
    es: "Género",
    it: "Genere",
  },
  code: {
    en: "ZIP Code",
    es: "Código",
    it: "Codice",
  },
  phone: {
    en: "Phone",
    es: "Teléfono",
    it: "Telefono",
  },
  nationality: {
    en: "Nationality",
    es: "Nacionalidad",
    it: "Nazionalità",
  },
  timeZone: {
    en: "Select Time Zone",
    es: "Selecciona la zona horaria",
    it: "Seleziona Fuso orario",
  },
  dateOfBirth: {
    en: "Date Of Birth",
    es: "Fecha de nacimiento",
    it: "Data di nascita",
  },
  city: {
    en: "City",
    es: "Ciudad de nacimiento",
    it: "Città di nascita",
  },
  state: {
    en: "State",
    es: "Estado de nacimiento",
    it: "Stato di nascita",
  },
  country: {
    en: "Country",
    es: "Nombre del país",
    it: "Nome del paese",
  },
  fiscalCode: {
    en: "Fiscal Code",
    es: "Código fiscal",
    it: "Codice fiscale",
  },
  address: {
    en: "Address",
    es: "Dirección",
    it: "Indirizzo",
  },
  taxIdentificationCode: {
    en: "Tax Identification Code",
    es: "Código de Identificación Tributaria",
    it: "Codice di Identificazione Fiscale",
  },
  pec: {
    en: "PEC",
    es: "PEC",
    it: "PEC",
  },
  businessEmail: {
    en: "Business Email",
    es: "Correo electrónico empresarial",
    it: "Email aziendale",
  },
  language: {
    en: "Language",
    es: "Idioma",
    it: "Lingua",
  },
  personalQualification: {
    en: "Personal Qualification",
    es: "Calificación personal",
    it: "Qualifica personale",
  },
  membershipName: {
    en: "Membership Name",
    es: "Nombre de la membresía",
    it: "Nome dell'iscrizione",
  },
  membershipRegistrationNumber: {
    en: "Membership Registration Number",
    es: "Número de registro de membresía",
    it: "Numero di registrazione dell'iscrizione",
  },
  membershipRegistrationDate: {
    en: "Membership Registration Date",
    es: "Fecha de registro de membresía",
    it: "Data di registrazione dell'iscrizione",
  },
  insuranceCompaneyName: {
    en: "Insurance Company Name",
    es: "Nombre de la empresa de seguros",
    it: "Nome della compagnia di assicurazione",
  },
  insuranceNumber: {
    en: "Insurance Number",
    es: "Número de seguro",
    it: "Numero di assicurazione",
  },
  insuranceExpirationDate: {
    en: "Insurance Expiration Date",
    es: "Fecha de vencimiento del seguro",
    it: "Data di scadenza dell'assicurazione",
  },
  shortDescription: {
    en: "Short Description (Max 200 Characters)",
    es: "Descripción breve (Máx. 200 caracteres)",
    it: "Descrizione breve (Max 200 caratteri)",
  },
  longDescription: {
    en: "Long Description (Max 800 Characters)",
    es: "Descripción larga (Máx. 800 caracteres)",
    it: "Descrizione lunga (Max 800 caratteri)",
  },
  interventionArea: {
    en: "Intervention Area",
    es: "Área de intervención",
    it: "Area di intervento",
  },
  startTime: {
    en: "Start Time",
    es: "Hora de inicio",
    it: "Ora di inizio",
  },
  endTime: {
    en: "End Time",
    es: "Hora de finalización",
    it: "Ora di fine",
  },
  currency: {
    en: "Currency",
    es: "Moneda",
    it: "Moneta",
  },
  service: {
    en: "Service",
    es: "Servicio",
    it: "Servizio",
  },
  mode: {
    en: "Mode",
    es: "Modo",
    it: "Modo",
  },
  priceMnt: {
    en: "Price/Minutes",
    es: "Precio/Minutos",
    it: "Prezzo/Minuti",
  },
  email: {
    en: "Email",
    es: "Email",
    it: "Email",
  },
};
export const dayNames = {
  sunday: {
    en: "Sunday",
    es: "Domingo",
    it: "Domenica",
  },
  monday: {
    en: "Monday",
    es: "Lunes",
    it: "Lunedì",
  },
  tuesday: {
    en: "Tuesday",
    es: "Martes",
    it: "Martedì",
  },
  wednesday: {
    en: "Wednesday",
    es: "Miércoles",
    it: "Mercoledì",
  },
  thursday: {
    en: "Thursday",
    es: "Jueves",
    it: "Giovedì",
  },
  friday: {
    en: "Friday",
    es: "Viernes",
    it: "Venerdì",
  },
  saturday: {
    en: "Saturday",
    es: "Sábado",
    it: "Sabato",
  },
};
export const appointmentLang = {
  pastAppointments: {
    en: "Past Appointments",
    es: "Citas Pasadas",
    it: "Appuntamenti Passati",
  },
  appointmensInProgress: {
    en: "Appointments in Progress",
    es: "Citas en Progreso",
    it: "Appuntamento in Corso",
  },
  upcomingAppointments: {
    en: "Upcoming Appointments",
    es: "Citas Próximas",
    it: "Prossimi Appuntamenti",
  },
  addNote: {
    en: "Add Note",
    es: "Agregar Nota",
    it: "AGGIUNGI NOTA",
  },
  note: {
    en: "Note",
    es: "Nota",
    it: "Nota",
  },
  active: {
    en: "Join the call",
    es: "Únase a la llamada",
    it: "Partecipa alla chiamata",
  },
  closed: {
    en: "Closed",
    es: "Cerrado",
    it: "Chiuso",
  },
  youHadAppointmentWith: {
    en: "You had an appointment with",
    es: "Tuviste una cita con",
    it: "Hai avuto un appuntamento con",
  },
  upcomingAppointmentWith: {
    en: "You will have an appointment with",
    es: "Tendrás una cita con",
    it: "Avrai un appuntamento con",
  },
  inProgressAppointment: {
    en: "You are having an appointment with",
    es: "Tienes una cita con",
    it: "Hai un appuntamento con",
  },
};
export const dashboardLang = {
  pastAppointments: {
    en: "Past Appointments",
    es: "Citas pasadas",
    it: "Appuntamenti passati",
  },
  upcomingAppointments: {
    en: "Upcoming Appointments",
    es: "Citas futuras",
    it: "Appuntamenti futuri",
  },
  reviews: {
    en: "Reviews",
    es: "Revisión",
    it: "Recensioni",
  },
};
export const appointmentModalLang = {
  title1: {
    en: "Book an interview with",
    es: "Reserva una entrevista con",
    it: "Prenota un colloquio con",
  },
  title2: {
    en: "AVAILABLE TIME SLOTS ACCORDING",
    es: "HORARIOS DISPONIBLES SEGÚN",
    it: "FASCE ORARIE DISPONIBILI SECONDO",
  },
  title3: {
    en: "Guaranteed <span class='font-bold'>safe and secure</span> payment",
    es: "Pago <span class='font-bold'>seguro y garantizado</span>",
    it: "Pagamento <span class='font-bold'>sicuro e garantito</span>",
  },
  title4: {
    en: "I have read and accept the Informed Consent to the Psychological Consult.",
    es: "He leído y acepto el Consentimiento Informado para la Consulta Psicológica.",
    it: "Ho letto e accetto il Consenso informato alla consulenza psicologica",
  },
  title5: {
    en: "I have read, understood and accept Prontopsy's data and privacy policy.",
    es: "He leído, entiendo y acepto la política de privacidad y datos de Prontopsy.",
    it: "Ho letto, compreso e accetto i datie l'informativa sulla privacy di Prontopsy.",
  },
  title6: {
    en: "Appointment has been booked and amount authorised.",
    es: "Cita reservada y cantidad autorizada.",
    it: "Appuntamento prenotato e importo autorizzato.",
  },
  title7: {
    en: "No Cards Available, Add New Card.",
    es: "No hay tarjetas disponibles, agregue una nueva tarjeta.",
    it: "Non ci sono carte disponibili, aggiungere una nuova carta.",
  },
  title8: {
    en: "Your card has already been added. If you want to add another card, enter your card details and click the Add Card button.",
    es: "Tu tarjeta ya ha sido agregada. Si desea agregar otra tarjeta, ingrese los detalles de su tarjeta y haga clic en el botón Agregar tarjeta.",
    it: "La tua carta è già stata aggiunta. Se desideri aggiungere un'altra carta, inserisci i dettagli della tua carta e fai clic sul pulsante Aggiungi carta.",
  },
  title9: {
    en: "You did not added any card yet. If you want to add any card, enter your card details and click the Add Card button.",
    es: "Aún no agregaste ninguna tarjeta. Si desea agregar alguna tarjeta, ingrese los datos de su tarjeta y haga clic en el botón Agregar tarjeta.",
    it: "Non hai ancora aggiunto alcuna carta. Se desideri aggiungere una carta, inserisci i dettagli della carta e fai clic sul pulsante Aggiungi carta.",
  },
  title10: {
    en: "You are going to have a call in real time with",
    es: "Vas a tener una llamada en tiempo real con",
    it: "Avrai una chiamata in tempo reale con",
  },
  title11: {
    en: "Maximum Amount Captured",
    es: "Cantidad máxima capturada",
    it: "Importo massimo catturato",
  },
  poweredBy: {
    en: "Powered by",
    es: "Potenciado por",
    it: "Potenziato da",
  },
  voice: {
    en: "Voice",
    es: "Voz",
    it: "Voce",
  },
  video: {
    en: "Video",
    es: "Video",
    it: "Video",
  },
  chat: {
    en: "Chat",
    es: "Charlar",
    it: "Chiacchierata",
  },
  continue: {
    en: "Continue",
    es: "Continuar",
    it: "Continua",
  },
};
export const findPsychologistLang = {
  search: {
    en: "Search",
    es: "Buscar",
    it: "Ricerca",
  },
  nationality: {
    en: "Nationality",
    es: "Nacionalidad",
    it: "Nazionalità",
  },
  language: {
    en: "Language",
    es: "Idioma",
    it: "Lingua",
  },
  areaOfIntervention: {
    en: "Area Of Intervention",
    es: "Área De Intervención",
    it: "Area di intervento",
  },
  gender: {
    en: "Gender",
    es: "Género",
    it: "Genere",
  },
  mode: {
    en: "Mode",
    es: "Modo",
    it: "Modalità",
  },
  therapeuticApproach: {
    en: "Therapeutic Approach",
    es: "Enfoque Terapéutico",
    it: "Approccio terapeutico",
  },
  mental: {
    en: "Mental And Neurological Disorders",
    es: "Trastornos Mentales Y Neurológicos",
    it: "Disturbi Mentali E Neurologici",
  },
  psychologicalProblems: {
    en: "Psychological Problems",
    es: "Problemas Psicológicos",
    it: "Problemi Psicologici",
  },
  otherTopics: {
    en: "Other Topics",
    es: "Otros Temas",
    it: "Altri argomenti",
  },
  currency: {
    en: "Currency",
    es: "Divisa",
    it: "Valuta",
  },
  select: {
    en: "Select",
    es: "Seleccionar",
    it: "Selezionare",
  },
};
export const appointmentDetailsLang = {
  appDetails: {
    en: "Details",
    es: "Detalles",
    it: "Dettagli",
  },
  appPayments: {
    en: "Payments",
    es: "Pagos",
    it: "Pagamenti",
  },
  appReviews: {
    en: "Reviews",
    es: "Reseñas",
    it: "Recensioni",
  },
  clientBusinessTitle: {
    en: "Client Business Information",
    es: "Información comercial del cliente",
    it: "Informazioni commerciali del cliente",
  },
  consultantBusinessTitle: {
    en: "Consultant Business Information",
    es: "Información comercial del consultor",
    it: "Informazioni commerciali del consulente",
  },
  businessName: {
    en: "BUSINESS NAME",
    es: "NOMBRE COMERCIAL",
    it: "NOME COMMERCIALE",
  },
  consultantName: {
    en: "CONSULTANT NAME",
    es: "NOMBRE DEL CONSULTOR",
    it: "NOME DEL CONSULENTE",
  },
  professionalQualification: {
    en: "PROFESSIONAL QUALIFICATION",
    es: "CALIFICACIÓN PROFESIONAL",
    it: "QUALIFICAZIONE PROFESSIONALE",
  },
  fiscalInformation: {
    en: "Fiscal Information",
    es: "Información Fiscal",
    it: "Informazioni Fiscali",
  },
  businessAddress: {
    en: "BUSINESS ADDRESS",
    es: "DIRECCIÓN COMERCIAL",
    it: "INDIRIZZO COMMERCIALE",
  },
  businessCity: {
    en: "BUSINESS CITY",
    es: "CIUDAD COMERCIAL",
    it: "CITTÀ COMMERCIALE",
  },
  fiscalCode: {
    en: "FISCAL CODE",
    es: "CÓDIGO FISCAL",
    it: "CODICE FISCALE",
  },
  pec: {
    en: "PEC",
    es: "PEC",
    it: "PEC",
  },
  businessProvince: {
    en: "BUSINESS PROVINCE",
    es: "PROVINCIA COMERCIAL",
    it: "PROVINCIA COMMERCIALE",
  },
  personalEmail: {
    en: "PERSONAL EMAIL",
    es: "CORREO PERSONAL",
    it: "EMAIL PERSONALE",
  },
  sdi: {
    en: "SDI",
    es: "SDI",
    it: "SDI",
  },
  taxIdentificationCode: {
    en: "TAX IDENTIFICATION CODE",
    es: "CÓDIGO DE IDENTIFICACIÓN FISCAL",
    it: "CODICE DI IDENTIFICAZIONE FISCALE",
  },
  businessCountry: {
    en: "BUSINESS COUNTRY",
    es: "PAÍS COMERCIAL",
    it: "PAESE COMMERCIALE",
  },
  zipcode: {
    en: "ZIPCODE",
    es: "CÓDIGO POSTAL",
    it: "CAP",
  },
  insuranceInformation: {
    en: "Insurance Information",
    es: "Información del Seguro",
    it: "Informazioni sull'Assicurazione",
  },
  insuranceCompanyName: {
    en: "INSURANCE COMPANY NAME",
    es: "NOMBRE DE LA COMPAÑÍA DE SEGUROS",
    it: "NOME DELLA COMPAGNIA DI ASSICURAZIONE",
  },
  insuranceNumber: {
    en: "INSURANCE NUMBER",
    es: "NÚMERO DE SEGURO",
    it: "NUMERO DI ASSICURAZIONE",
  },
  insuranceExpirationDate: {
    en: "INSURANCE EXPIRATION DATE",
    es: "FECHA DE VENCIMIENTO DEL SEGURO",
    it: "DATA DI SCADENZA DELL'ASSICURAZIONE",
  },
  otherInformation: {
    en: "Other Information",
    es: "Otra Información",
    it: "Altre Informazioni",
  },
  nota: {
    en: "NOTE",
    es: "NOTA",
    it: "NOTA",
  },
  membershipInformation: {
    en: "Membership Information",
    es: "Información de Membresía",
    it: "Informazioni sull'Iscrizione",
  },
  city: {
    en: "CITY",
    es: "CIUDAD",
    it: "CITTÀ",
  },
  membershipName: {
    en: "MEMBERSHIP NAME",
    es: "NOMBRE DE LA MEMBRESÍA",
    it: "NOME DELLA ISCRIZIONE",
  },
  region: {
    en: "REGION",
    es: "REGIÓN",
    it: "REGIONE",
  },
  membershipRegistrationNo: {
    en: "MEMBERSHIP REGISTRATION NO.",
    es: "NÚMERO DE REGISTRO DE MEMBRESÍA",
    it: "NUMERO DI REGISTRAZIONE DELLA ISCRIZIONE",
  },
  membershipRegistrationDate: {
    en: "MEMBERSHIP REGISTRATION DATE",
    es: "FECHA DE REGISTRO DE MEMBRESÍA",
    it: "DATA DI REGISTRAZIONE DELLA ISCRIZIONE",
  },
  serviceInformation: {
    en: "Service Information",
    es: "Servicio de información",
    it: "Informazioni sul servizio",
  },
  serviceOffered: {
    en: "SERVICE OFFERED",
    es: "SERVICIO OFRECIDO",
    it: "SERVIZIO OFFERTO",
  },
  mode: {
    en: "MODE",
    es: "MODO",
    it: "MODALITÀ",
  },
  dateOfAppointMent: {
    en: "DATE OF APPOINTMENT",
    es: "FECHA DE NOMBRAMIENTO",
    it: "DATA DELL'APPUNTAMENTO",
  },
  startTime: {
    en: "START TIME",
    es: "HORA DE INICIO",
    it: "ORA DI INIZIO",
  },
  endTime: {
    en: "END TIME",
    es: "HORA DE FINALIZACIÓN",
    it: "TEMPO SCADUTO",
  },
  consultantJoinTime: {
    en: "CONSULTANT JOIN TIME",
    es: "HORA DE INGRESO DEL CONSULTOR",
    it: "ORARIO DI INGRESSO DEL CONSULENTE",
  },
  paymentInformation: {
    en: "Payment Information",
    es: "Información de pago",
    it: "Informazioni sul pagamento",
  },
  paymentStatus: {
    en: "PAYMENT STATUS",
    es: "ESTADO DEL PAGO",
    it: "STATO DEL PAGAMENTO",
  },
  currency: {
    en: "CURRENCY",
    es: "MONEDA",
    it: "VALUTA",
  },
  pricePerMinute: {
    en: "PRICE/MINUTES",
    es: "PRECIO/MINUTOS",
    it: "PREZZO/MINUTI",
  },
  totalDurationTime: {
    en: "TOTAL DURATION TIME",
    es: "TIEMPO TOTAL DE DURACIÓN",
    it: "TEMPO TOTALE DI DURATA",
  },
  authorizeAmount: {
    en: "AUTHORIZE AMOUNT",
    es: "MONTO AUTORIZADO",
    it: "IMPORTO AUTORIZZATO",
  },
  payableAmount: {
    en: "PAYABLE AMOUNT",
    es: "MONTO A PAGAR",
    it: "IMPORTO DA PAGARE",
  },
  capturedAmount: {
    en: "CAPTURED AMOUNT",
    es: "MONTO CAPTURADO",
    it: "IMPORTO CATTURATO",
  },
  paymentMethods: {
    en: "PAYMENT METHODS",
    es: "MÉTODOS DE PAGO",
    it: "METODI DI PAGAMENTO",
  },
  checkboxLabel1: {
    en: "I have read and accept the Informed Consent for Psychological Consultation.",
    es: "He leído y acepto el Consentimiento Informado para Consulta Psicol",
    it: "Ho letto e accettato il Consenso Informato per la Consultazione Ps",
  },
  checkboxLabel2: {
    en: "I have read, understood and accept Prontopsy's data and privacy policy.",
    es: "He leído, comprendido y acepto la política de privacidad y datos de Prontopsy.",
    it: "Ho letto, compreso e accetto i dati e l'informativa sulla privacy di Prontopsy",
  },
  disclaimer: {
    en: "DISCLAIMER",
    es: "DESCARGO DE RESPONSABILIDAD",
    it: "DISCLAIMER",
  },
  disclaimerText: {
    en: "The Consultant call rate provides for an advance charge for each minute of consulting and for each subsequent fraction of a minute, rounded up to the next unit of time.",
    es: "Descargo de responsabilidad: La tarifa de la llamada del Consultor prevé un cargo por adelantado por cada minuto de consulta y por cada fracción subsiguiente de un minuto, redondeado al siguiente intervalo de tiempo.",
    it: "La tariffa della chiamata del consulente prevede un addebito anticipato per ogni minuto di consulenza e per ogni successiva frazione di un minuto, arrotondato all'unità di tempo successiva.",
  },
};

export const documentLang = {
  appointmentId: {
    en: "APPOINTMENT ID",
    es: "ID DE CITA",
    it: "ID APPUNTAMENTO",
  },
  printKeep: {
    en: "PRINT AND KEEP THIS PAGE.",
    es: "IMPRIMA Y CONSERVE ESTA PÁGINA.",
    it: "STAMPA E CONSERVA QUESTA PAGINA.",
  },
  dateOfAppointment: {
    en: "Date of Appointment",
    es: "Fecha de la Cita",
    it: "Data dell'Appuntamento",
  },
  prontopsyId: {
    en: "Prontopsy Appointment ID",
    es: "ID de Cita Prontopsy",
    it: "ID Appuntamento Prontopsy",
  },
  totalCapturedAmount: {
    en: "Total Captured Amount",
    es: "Monto Total Capturado",
    it: "Importo Totale Catturato",
  },
  clientDetails: {
    en: "Client Details",
    es: "Detalles del Cliente",
    it: "Dettagli del Cliente",
  },
  consultantDetails: {
    en: "Consultant Details",
    es: "Detalles del Consultor",
    it: "Dettagli del Consulente",
  },
  paymentInformation: {
    en: "Payment Information",
    es: "Información de Pago",
    it: "Informazioni sul Pagamento",
  },
  serviceProvided: {
    en: "Service Provided",
    es: "Servicio Prestado",
    it: "Servizio Fornito",
  },
  mode: {
    en: "Mode",
    es: "Modo",
    it: "Modo",
  },
  dateTime: {
    en: "Date & Time",
    es: "Fecha y Hora",
    it: "Data e Ora",
  },
  totalDuration: {
    en: "Total Duration",
    es: "Duración Total",
    it: "Durata Totale",
  },
  consultantCallRate: {
    en: "Consultant Call Rate",
    es: "Tarifa de Llamada del Consultor",
    it: "Tariffa della Chiamata del Consulente",
  },
  authorizedAmount: {
    en: "Authorized Amount",
    es: "Monto Autorizado",
    it: "Importo Autorizzato",
  },
  capturedAmount: {
    en: "Captured Amount",
    es: "Monto Capturado",
    it: "Importo Catturato",
  },
  paymentGateway: {
    en: "Payment Gateway",
    es: "Pasarela de Pago",
    it: "Gateway di Pagamento",
  },
  disclaimer: {
    en: "Disclaimer:",
    es: "Descargo de Responsabilidad:",
    it: "Disclaimer:",
  },
  disclaimerText: {
    en: "The Consultant call rate is provided as an advance charge for each minute of consulting and for each subsequent fraction of time a prorate fee may apply.",
    es: "La tarifa de la llamada del Consultor se proporciona como un cargo por adelantado por cada minuto de consulta y para cada fracción de tiempo subsiguiente se puede aplicar una tarifa prorrateada.",
    it: "La tariffa della chiamata del consulente viene fornita come addebito anticipato per ogni minuto di consulenza e per ogni successiva frazione di tempo può essere applicata una tariffa proporzionale.",
  },
  termsAndConditions: {
    en: "General Terms and Conditions",
    es: "Términos y Condiciones Generales",
    it: "Termini e Condizioni Generali",
  },
  privacyPolicy: {
    en: "Privacy Policy",
    es: "Política de Privacidad",
    it: "Politica sulla Privacy",
  },
  footer: {
    en: "©2024, Prontopsy By Global Digital Vagabonds Ltd.",
    es: "©2024, Prontopsy By Global Digital Vagabonds Ltd.",
    it: "©2024, Prontopsy By Global Digital Vagabonds Ltd.",
  },
  consultantName: {
    en: "Consultant Name",
    es: "Nombre del Consultor",
    it: "Nome del Consulente",
  },
  address: {
    en: "Address",
    es: "Dirección",
    it: "Indirizzo",
  },
  city: {
    en: "City",
    es: "Ciudad",
    it: "Città",
  },
  zipCode: {
    en: "ZIP Code",
    es: "Código Postal",
    it: "CAP",
  },
  country: {
    en: "Country",
    es: "País",
    it: "Paese",
  },
  taxCode: {
    en: "Tax Identification Code",
    es: "Código de Identificación Fiscal",
    it: "Codice Fiscale",
  },
  email: {
    en: "Email",
    es: "Correo Electrónico",
    it: "Email",
  },
  clientCode: {
    en: "Client Code",
    es: "Código de Cliente",
    it: "Codice Cliente",
  },
  clientName: {
    en: "Client Name",
    es: "Nombre del Cliente",
    it: "Nome del Cliente",
  },
};
