import React, { Component, useEffect, useState } from "react";
import Tabs from "../../components/elements/Tabs";
import InformazioniPersonall from "../../components/elements/tabContent/profile/profilo/InformazioniPersonall";
import BillingInfo from "../../components/elements/tabContent/profile/profilo/BillingInfo";
import PaymentInfo from "../../components/elements/tabContent/profile/profilo/PaymentInfo";
import AccountInformation from "../../components/elements/tabContent/profile/profilo/AccountInformation";
import MembershipInformation from "../../components/elements/tabContent/profile/profilo/MembershipInformation";
import InsuranceInfo from "../../components/elements/tabContent/profile/profilo/InsuranceInfo";
import Biography from "../../components/elements/tabContent/profile/profilo/Biography";
import InterventionSpecializationInfo from "../../components/elements/tabContent/profile/profilo/InterventionSpecializationInfo";
import LanguageInfo from "../../components/elements/tabContent/profile/profilo/LanguageInfo";
import ServiceOffered from "../../components/elements/tabContent/profile/profilo/ServiceOffered";
import DailySchedule from "../../components/elements/tabContent/profile/profilo/DailySchedule";
import { profile } from "../../components/elements/language";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileDetails } from "../../service/profileService";
import { setProfileData } from "../../store/slice/profileSlice";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
import { useLocation } from "react-router-dom";
import { addStripeAccountService } from "../../service/stripeService";
import { toast } from "react-toastify";
import CheckProfile from "../../middlewares/CheckProfile";
import TabsForProfile from "../../components/elements/TabsForProfile";

const MyProfile = () => {
  const language = useSelector((state) => state?.languageSlice?.data);
  const userId = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const [loaded, setLoaded] = useState(true);
  // const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const role = profileData && profileData?.roleCode;
  const dispatch = useDispatch();
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");

  useEffect(() => {
    code &&
      !profileData?.stripeAccountId &&
      addStripeAccountService({ stripeAccountId: code }).then((res) => {
        if (res.data.status == 200) {
          toast.success(res.data.message);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(setTabSelection({}));
  }, []);

  // useEffect(() => {
  //   fetchProfileDetails(userId, setProfiledata, setLoaded);
  // }, []);

  // useEffect(() => {
  //   dispatch(setProfileData(profileData));
  // }, [profileData]);

  const tabData = [
    {
      _id: 1,
      name: profile?.personalInformation?.[language],
      component: (
        <InformazioniPersonall
          data={profileData}
          setData={setProfileData}
          dataLoaded={loaded}
        />
      ),
    },
    {
      _id: 2,
      name: profile?.billingInformation?.[language],
      component: (
        <BillingInfo
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 3,
      name: profile?.paymentInformation?.[language],
      component: (
        <PaymentInfo
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 4,
      name: profile?.accountInformation?.[language],
      component: (
        <AccountInformation
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 5,
      name: profile?.memberInformation?.[language],
      component: (
        <MembershipInformation
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 6,
      name: profile?.insuranceInformation?.[language],
      component: (
        <InsuranceInfo
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 7,
      name: profile?.biography?.[language],
      component: (
        <Biography
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 8,
      name: profile?.specialiazion?.[language],
      component: (
        <InterventionSpecializationInfo
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 9,
      name: profile?.languageInformation?.[language],
      component: (
        <LanguageInfo
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 10,
      name: profile?.serviceOffered?.[language],
      component: (
        <ServiceOffered
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 11,
      name: profile?.dailySchedule?.[language],
      component: (
        <DailySchedule
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
  ];
  const userTabData = [
    {
      _id: 1,
      name: profile?.personalInformation?.[language],
      component: (
        // <CheckProfile>
        <InformazioniPersonall
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
        // </CheckProfile>
      ),
    },
    {
      _id: 2,
      name: profile?.billingInformation?.[language],
      component: (
        // <CheckProfile>
        <BillingInfo
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
        // </CheckProfile>
      ),
    },
    {
      _id: 3,
      name: profile?.paymentInformation?.[language],
      component: (
        <PaymentInfo
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 4,
      name: profile?.accountInformation?.[language],
      component: (
        <AccountInformation
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
      ),
    },
    {
      _id: 5,
      name: profile?.languageInformation?.[language],
      component: (
        // <CheckProfile>
        <LanguageInfo
          data={profileData}
          dataLoaded={loaded}
          setData={setProfileData}
        />
        // </CheckProfile>
      ),
    },
  ];

  return (
    <section className="relative py-5">
      <div className="relative">
        <TabsForProfile
          tabs={role ? (role === "user" ? userTabData : tabData) : ""}
          loaded={loaded}
          mainClasses={"flex-col"}
          divClass={"w-full md:flex-wrap !gap-2"}
          textClasses={
            "font-UnisonPro !font-bold italic !leading-tight !text-[11px] !leading-[15px] !whitespace-normal text-prontopsy-blue"
          }
          buttonClass={
            "max-w-48 w-full !rounded-md !py-0 h-14 flex items-center justify-center !border-0"
          }
          tabContentdivClasses={"w-full"}
          activeClass={"!px-10 !text-prontopsy-blue"}
        />
      </div>
    </section>
  );
};

export default MyProfile;
