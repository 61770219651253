import React from "react";
import Button from "../form/Button";
import { classNames } from "../../helpers/classNames";
import { useSelector } from "react-redux";
import { detailsBtn } from "../elements/language";

const InterventionItem = ({ data }) => {
  const language = useSelector((state) => state?.languageSlice?.data);

  return (
    <>
      <div
        className={classNames(
          "relative overflow-hidden rounded-3xl p-4 flex xl:w-[30%] md:w-[45%] w-[90%]",
          data?.styleType === 1
            ? "bg-prontopsy-blue"
            : data?.styleType === 2
            ? "bg-prontopsy-pink"
            : data?.styleType === 3
            ? "bg-prontopsy-yellow"
            : "bg-prontopsy-blue"
        )}
      >
        <div className="border border-white w-full rounded-2xl py-10 3xl:px-16 lg:px-10 md:px-5 text-center">
          <div className="w-16 md:w-20 xl:w-24 aspect-square bg-white/75 rounded-full mx-auto mb-4 flex items-center justify-center">
            <div className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold text-black">
              {data?.relatedConsultantCount}
            </div>
          </div>

          {data?.description && (
            <div
              className="text-[17px] leading-[1.2] !text-white mb-4 w-full max-w-xs mx-auto uppercase"
              dangerouslySetInnerHTML={{
                __html: data?.description?.[language],
              }}
            ></div>
          )}
          {data.title && (
            <h4
              className="font-UnisonPro font-bold italic text-base md:text-lg lg:text-[20px] text-white !leading-[1.1]"
              dangerouslySetInnerHTML={{ __html: data?.title?.[language] }}
            ></h4>
          )}

          <div className="flex justify-center mt-6">
            <Button
              buttonHasLink={true}
              buttonLink={`/intervention-details/${data?._id}?title=${data?.title?.[language]}`}
              buttonLabel={detailsBtn?.[language]}
              buttonClasses={
                "!h-10 lg:!h-12 !bg-white !text-black hover:!bg-white !px-12"
              }
              buttonLabelClasses={"!text-[base]"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InterventionItem;
