import { useEffect, useRef, useState } from "react";
import { useSocket } from "../components/socket/SocketProvider";
import { appointmentClose } from "../service/videoCallService";

function usePageVisibility(token, socket, userId) {
  const offlineTimeoutRef = useRef(null);
  const inactivityTimeoutRef = useRef(null);
  const [isOnline, setIsOnline] = useState(false);
  const localAppointmentData = localStorage.getItem("appointmentData");
  const appointment = localAppointmentData && JSON.parse(localAppointmentData);

  useSocket("userStatusResponse", (data) => {
    if (userId === data?.userId) {
      setIsOnline(data.online);
    }
  });

  useEffect(() => {
    const emitUserOffline = () => {
      appointment &&
        appointment?.appointmentId &&
        appointmentClose({ appointmentId: appointment?.appointmentId });

      if (socket && userId) {
        socket.emit("consultantBusy", {
          consultantId: appointment?.consultantId,
          isBusy: false,
        });
        localStorage.removeItem("appointmentData");
        socket.emit("userOffline", userId);
        socket.emit("chatDisconnect", {
          disconnect: true,
          appointmentId: appointment?.appointmentId,
        });
        socket.emit("videoSessionDisconnect", {
          disconnect: true,
          appointmentId: appointment?.appointmentId,
        });
      }
    };

    // const handleBeforeUnload = (event) => {
    // event.preventDefault();
    // event.returnValue = "";
    // emitUserOffline();
    // console.log("beforeUnload");
    // socket.emit("userOffline", userId);
    // };
    // const handleUnload = () => {
    //   console.log("unload");
    //   socket.emit("userOnline", userId);
    // };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        offlineTimeoutRef.current = setTimeout(() => {
          emitUserOffline();
        }, 5 * 60 * 1000); // 1 minute
      } else {
        clearTimeout(offlineTimeoutRef.current);
        offlineTimeoutRef.current = null;
      }
    };

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeoutRef.current);

      inactivityTimeoutRef.current = setTimeout(() => {
        emitUserOffline();
      }, 30 * 60 * 1000); // 30 minutes of inactivity
    };

    // Attach event listeners for visibility change, beforeunload, and user activity
    // window.addEventListener("beforeunload", handleBeforeUnload);
    // window.addEventListener("unload", handleUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("mousemove", resetInactivityTimeout);
    document.addEventListener("keydown", resetInactivityTimeout);

    // Start the inactivity timer initially
    resetInactivityTimeout();

    // Cleanup on component unmount
    return () => {
      // window.removeEventListener("beforeunload", handleBeforeUnload);
      // window.removeEventListener("unload", handleUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("mousemove", resetInactivityTimeout);
      document.removeEventListener("keydown", resetInactivityTimeout);

      if (offlineTimeoutRef.current) {
        clearTimeout(offlineTimeoutRef.current);
      }
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, [token, socket, userId, appointment?.consultantId]);

  return null;
}

export default usePageVisibility;
