import React, { useEffect, useState } from "react";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";
import Modal from "./Modal";
import Cards from "./payment/Cards";
import AddNewCards from "./payment/AddNewCards";
import { useDispatch, useSelector } from "react-redux";
import { appointmentModalLang } from "./language";
import SuccessModal from "./SuccessModal";
import { toast } from "react-toastify";
import RadioButton from "../form/RadioButton";
import { bookAppointment } from "../../service/videoCallService";
import { setToken } from "../../store/slice/vonageSlice";
import { setCallerData } from "../../store/slice/callingSlice";
import { useSocket } from "../socket/SocketProvider";
import { JoinChatConfirmModal } from "./JoinChatConfirmationModal";
import JoinCallConfirmationModal from "./JoinCallConfirmationModal";
import useBatteryStatus from "./hooks/useBatteryStatus";
import useInternetSpeed from "./hooks/useInternetSpeed";
import SecondResume from "./SecondResume";

const CallingModal = ({ isOpen, setIsOpen, data, serviceId, modes }) => {
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState(null);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const [checked, setChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);
  const [isPrivecyAccepted, setIsPrivecyAccepted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCardAvailable, setIsCardAvailable] = useState(true);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [loading, setLoading] = useState(false);
  const socket = useSocket();
  const [selectedCard, setSelectedCard] = useState(null);
  const [modeId, setModeId] = useState(null);
  const [isOpenConfirmationChatModal, setIsOpenConfirmationChatModal] =
    useState(false);
  const [callConfirmModal, setCallConfirmModal] = useState(false);

  useEffect(() => {
    modes.find((elm) => {
      if (elm?.slug == "video") {
        setSelectedOption("video");
        setModeId(elm?._id);
      }
    });
  }, [modes]);

  const handleOptionChange = (option, id) => {
    setSelectedOption(option);
    setModeId(id);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const callerData = {
    user: {
      name: userDetails?.firstName + " " + userDetails?.lastName,
      email: userDetails?.email,
      image: userDetails?.image?.url,
      id: userDetails?._id,
    },
    consultant: {
      name: data?.name,
      image: data?.image,
      id: data?._id,
    },
    mode: selectedOption,
  };

  //-----------------Internet Speed------------------
  const { speed, error, checkInternetSpeed } = useInternetSpeed();
  useEffect(() => {
    checkInternetSpeed();
  }, []);

  //--------------------******--------------------------

  //--------------Battery Percentage------------------
  const { level, charging } = useBatteryStatus();

  const handleContinue = () => {
    if (charging === false && Math.round(level * 100) <= 5) {
      toast.error(
        "Your battery level is low (bellow 5%). Please charge your device and try again."
      );
      return;
    }

    if (!modeId) {
      toast.error("Select mode first.");
      return;
    }
    if (!isConsentAccepted) {
      toast.error("Check consent to the psychological consult first.");
      return;
    }
    if (checked) {
      toast.error("Please add your new card!");
      return;
    }
    if (!isPrivecyAccepted) {
      toast.error("Check privacy and policy first.");
      return;
    }
    if (speed && speed * 1 < 1) {
      alert(
        "Your network speed is too low. Please check your internet connection and try again."
      );
      return;
    }
    setLoading(true);

    bookAppointment({
      consultantId: data?._id,
      details: {
        appointmentDate: formatDate(new Date()),
      },
      modeId: modeId,
      serviceId: serviceId,
    }).then((res) => {
      if (res?.data?.status == 200 || res?.data?.status == 201) {
        const appointmentData = {
          appointmentId: res?.data?.data?.appointment?._id,
          consultantId: res?.data?.data?.appointment?.consultantId,
          appointmentStartTime:
            res?.data?.data?.appointment?.consultantJoinTime,
          mode: selectedOption,
          callerData: callerData,
          sessionId: res?.data?.data?.appointment?.sessionId,
          token: res?.data?.data?.appointment?.sessionToken,
          paymentMode: res?.data?.data?.appointment?.paymentMode,
        };
        localStorage.setItem(
          "appointmentData",
          JSON.stringify(appointmentData)
        );
        localStorage.setItem(
          "consultantId",
          JSON.stringify(res?.data?.data?.appointment?.consultantId)
        );
        socket &&
          socket.emit("consultantBusy", {
            consultantId: res?.data?.data?.appointment?.consultantId,
            isBusy: true,
          });

        setLoading(false);
        setIsOpen(false);
        if (selectedOption === "chat") {
          socket &&
            socket.emit("chatSession", {
              ...appointmentData,
              // ...callerData,
              // appointmentId: res?.data?.data?.appointment?._id,
              // appointmentStartTime:
              //   res?.data?.data?.appointment?.details?.appointmentStartTime,
            });
          setIsOpenConfirmationChatModal(true);
        } else if (selectedOption === "video" || selectedOption === "voice") {
          dispatch(setCallerData(callerData));
          socket &&
            socket.emit("consultantCall", {
              ...callerData,
              ...appointmentData,
            });
          socket &&
            socket.emit("vonageTokBoxSession", {
              // sessionId: res?.data?.data?.appointment?.sessionId,
              // token: res?.data?.data?.appointment?.sessionToken,
              ...appointmentData,
            });

          dispatch(
            setToken({
              loading: true,
              sessionId: res?.data?.data?.appointment?.sessionId,
              token: res?.data?.data?.appointment?.sessionToken,
              mode: selectedOption,
            })
          );
          setCallConfirmModal(true);
        }
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    });
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={appointmentModalLang?.title10?.[lang]}
        // modalTitle={""}
      >
        <Button
          buttonClasses={
            "!w-10 !h-10 !rounded-full !border-black !bg-transparent shadow-md !absolute !top-2 !right-2 !text-black !flex-row !items-center !justify-center"
          }
          buttonIcon={"fa-regular fa-xmark"}
          buttonIconPosition={"left"}
          buttonFunction={() => setIsOpen(false)}
        />
        <div className="relative space-y-2  ">
          <div className="relative">
            <div className="text-2xl font-bold text-prontopsy-blue">
              {data?.name}
            </div>
          </div>
          <div className="md:flex block w-full md:gap-52 md:space-y-0 spacey-4 items-center">
            <div className="relative flex flex-wrap justify-between gap-8 items-center my-1">
              <div className="relative flex text-xs text-left font-RobotoSlab gap-2 font-bold text-gray-600">
                {data?.rate?.value || "N/A"}{" "}
                <span>{data?.rate?.currency}/MIN</span>
              </div>
            </div>
            <div className="flex gap-2 text-sm font-bold text-gray-600">
              <div>{appointmentModalLang?.title11?.[lang]} :</div>
              <div>
                {data?.rate?.currency}{" "}
                <span> {data?.rate?.value * 60 || "N/A"}</span>
              </div>
            </div>
          </div>

          {/* M O D E S */}
          <div className="relative flex items-center flex-wrap gap-3 my-5 w-full">
            {modes &&
              modes.length > 0 &&
              modes.map((elm, index) => (
                <RadioButton
                  label={elm?.title?.[lang]}
                  icon={`${
                    elm?.slug == "voice"
                      ? "fa-solid fa-phone-volume"
                      : elm?.slug == "video"
                      ? "fas fa-video"
                      : "fa-solid fa-comments"
                  }`}
                  isSelected={selectedOption === elm?.slug}
                  onChange={() => handleOptionChange(elm?.slug, elm?._id)}
                  key={index}
                />
              ))}
          </div>

          <div className="flex items-start flex-col gap-1 !mb-4 space-y-1">
            {/*E X I S T I N G     C A R D S */}
            {!checked && (
              <Cards
                setIsCardAvailable={setIsCardAvailable}
                setSelectedCard={setSelectedCard}
              />
            )}

            {/* A D D   N E W   C A R D */}

            <AddNewCards checked={checked} setChecked={setChecked} />
          </div>

          <div className="bg-slate-200/50 w-full py-3 !my-3 px-4 rounded-md">
            <div className="md:flex items-center gap-5 md:space-y-0 space-y-2">
              <div className="flex md:gap-5 gap-3 w-full items-center">
                <span className="text-green-600 flex-shrink-0">
                  <i className="fa-solid fa-lock"></i>
                </span>
                <div
                  className="font-RobotoSlab flex-shrink w-full text-gray-700 text-sm font-normal"
                  dangerouslySetInnerHTML={{
                    __html: appointmentModalLang?.title3?.[lang],
                  }}
                />
              </div>
              <div className="h-8 min-w-40 border border-gray-400 flex items-center justify-center gap-2 rounded-md">
                <div className="font-RobotoSlab text-gray-400 text-xs font-normal">
                  Powered by
                </div>
                <div className="text-4xl text-gray-400">
                  <i class="fa-brands fa-stripe"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="relative space-y-4">
            <Checkbox
              checkboxLabel={appointmentModalLang?.title4?.[lang]}
              checkboxLabelClass={`!font-RobotoSlab ${
                isConsentAccepted ? "!text-black" : "!text-neutral-400"
              }`}
              isChecked={isConsentAccepted}
              onChange={(e) => setIsConsentAccepted(e.target.checked)}
            />
            <Checkbox
              checkboxLabel={appointmentModalLang?.title5?.[lang]}
              checkboxLabelClass={`!font-RobotoSlab ${
                isPrivecyAccepted ? "!text-black" : "!text-neutral-400"
              }`}
              isChecked={isPrivecyAccepted}
              onChange={(e) => setIsPrivecyAccepted(e.target.checked)}
            />
          </div>
          <div className="relative !mt-4">
            <Button
              buttonLabel={appointmentModalLang?.continue?.[lang]}
              buttonClasses={`!bg-prontopsy-blue !h-12 !px-6`}
              buttonLabelClasses={`!font-UnisonPro !text-xs !text-white !font-bold !italic`}
              buttonFunction={handleContinue}
              isDisable={loading}
              loading={loading}
            />
          </div>
        </div>
      </Modal>
      <SuccessModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setIsConsentAccepted={setIsConsentAccepted}
        setIsPrivecyAccepted={setIsPrivecyAccepted}
        setSelectedTime={setSelectedTime}
      />
      <JoinChatConfirmModal
        isOpen={isOpenConfirmationChatModal}
        setIsOpen={setIsOpenConfirmationChatModal}
      />
      <JoinCallConfirmationModal
        isOpen={callConfirmModal}
        setIsOpen={setCallConfirmModal}
        callerData={callerData}
      />
      {/* <SecondResume isOpen={callConfirmModal} setIsOpen={setCallConfirmModal} /> */}
    </div>
  );
};

export default CallingModal;
