import React, { useEffect, useState, useRef } from "react";
import AppointmentItem from "../../../../single/AppointmentItem";
import { useDispatch, useSelector } from "react-redux";
import { appointmentLang } from "../../../language";
import dayjs from "dayjs";
import SpinLoader from "../../../table/SpinLoader";
import {
  addConsultantAppointmentNote,
  addClientAppointmentNote,
  editAppointmentNote,
  fetchAppointmentList,
} from "../../../../../service/appointmentService";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../../NoDataFound";
import { toast } from "react-toastify";
import { setSelectedAppointment } from "../../../../../store/slice/appointmentSlice";

const langConfig = {
  payment: {
    en: "Payment amount",
    es: "Monto del pago",
    it: "Importo del pagamento",
  },
  status: {
    en: "Payment status",
    es: "Estado de pago",
    it: "Stato del pagamento",
  },
  id: {
    en: "Appointment ID",
    es: "ID de cita",
    it: "ID di appuntamento",
  },
};

const UpcomingEvents = () => {
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const language = useSelector((state) => state.languageSlice.data);
  const [appointmentData, setAppointmentData] = useState([]);
  const token = localStorage.getItem("auth_token");
  const [loaded, setLoaded] = useState(true);
  const [list, setList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [allList, setAllList] = useState([]);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [noteInput, setNoteInput] = useState("");
  const [selectedNoteData, setSelectedNoteData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedAppointment = useSelector(
    (state) => state.selectedAppointmentSlice?.data
  );

  useEffect(() => {
    setSelectedNoteData(selectedAppointment);
  }, [selectedAppointment]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        setScrolledToBottom(scrollTop + clientHeight >= scrollHeight - 10);
      }
    };

    const scrollableElement = scrollableRef.current;
    scrollableElement?.addEventListener("scroll", handleScroll);

    return () => {
      scrollableElement?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollableRef.current]);

  useEffect(() => {
    const fetchAppointments = async () => {
      setLoaded(false);
      const res = await fetchAppointmentList(
        token,
        { limit, "status[0]": "active", page },
        setList,
        setLoaded,
        setHasNextPage
      );

      if (res?.data?.docs?.status === 200) {
        const metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        setAllList((prevList) =>
          page === 1 ? res.data.docs.data : [...prevList, ...res.data.docs.data]
        );
      }
    };

    fetchAppointments();
  }, [page]);

  useEffect(() => {
    if (scrolledToBottom && hasNextPage) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [scrolledToBottom, hasNextPage]);

  useEffect(() => {
    const info = allList.map((elm) => ({
      id: elm._id,
      date: dayjs(elm?.createdAt).format("D"),
      month: dayjs(elm?.createdAt).format("MMMM"),
      time: `${dayjs(elm?.details?.appointmentStartTime).format(
        "h:mm"
      )} - ${dayjs(elm?.details?.appointmentEndTime).format("h:mm A")}`,
      note: ` ${appointmentLang?.upcomingAppointmentWith?.[language]} ${
        roleCode === "consultant"
          ? `${elm?.clientData?.firstName || ""} ${
              elm?.clientData?.lastName || ""
            }`
          : `${elm?.consultantData?.firstName || ""} ${
              elm?.consultantData?.lastName || ""
            }`
      }`,
      notes: elm?.relatedNoteData || null,
      mode: elm?.service?.mode?.title?.en,
      clientData: roleCode === "user" ? elm?.consultantData : elm?.clientData,
      sessionId: elm?.sessionId,
      sessionToken: elm?.sessionToken,
      paymentInfo: `<p>${langConfig?.payment?.[language]}: EUR ${elm?.details?.capturedAmount}</p><p>${langConfig?.status?.[language]}: ${elm?.details?.paymentStatus}</p><p>${langConfig?.id?.[language]}: ${elm?.details?.appointmentId}</p>`,
      buttonTextPink: appointmentLang?.addNote?.[language],
      buttonTextBlue: appointmentLang?.active?.[language],
      buttonIcon: "fa-solid fa-file",
      status: elm?.status,
      details: elm?.details,
      isTrue: true,
    }));
    setAppointmentData(info);
  }, [allList, language, isModalOpen]);
  console.log(allList, "all list");
  const handleNoteSubmit = async () => {
    if (!noteInput) {
      toast.error("Please enter your note!");
      return;
    }

    setLoaded(false);
    const info = {
      // userId: selectedNoteData?.clientData?._id,
      appointmentId: localStorage.getItem("selectedAppointment"),
      message: noteInput,
    };

    try {
      // if (!selectedNoteData?.notes?._id) {
      const addNote =
        roleCode === "consultant"
          ? await addConsultantAppointmentNote(info)
          : await addClientAppointmentNote(info);

      if (addNote?.data?.status === 200 || addNote?.data?.status === 201) {
        const data = {
          _id: addNote.data.data._id,
          consultantId: addNote.data.data.consultantId,
          message: addNote.data.data.message,
          userId: addNote.data.data.clientData?._id,
        };

        setNoteInput(addNote?.data?.data?.message);
        localStorage.removeItem("selectedAppointment");
        toast.success(addNote.data.message);
        setIsModalOpen(true);
        setSelectedNoteData(null);
        dispatch(setSelectedAppointment(null));
        setAllList((prevList) =>
          prevList.map((elm) =>
            elm._id === addNote.data.data.appointmentId
              ? { ...elm, relatedNoteData: data }
              : elm
          )
        );
      } else {
        toast.error(addNote.data.message);
      }
      // } else {
      //   const editNote = await editAppointmentNote(selectedNoteData.notes._id, {
      //     message: noteInput,
      //   });

      // if (editNote?.data?.status === 200) {
      //   toast.success(editNote.data.message);
      //   setIsModalOpen(true);
      //   setSelectedNoteData(null);
      //   dispatch(setSelectedAppointment(null));
      //   setAllList((prevList) =>
      //     prevList.map((elm) =>
      //       elm._id === editNote.data.data.appointmentId
      //         ? {
      //             ...elm,
      //             relatedNoteData: {
      //               ...elm.relatedNoteData,
      //               message: noteInput,
      //             },
      //           }
      //         : elm
      //     )
      //   );
      // }
    } catch (error) {
      toast.error("Error updating note!");
    } finally {
      setLoaded(true);
    }
  };

  return (
    <div>
      {allList.length === 0 && !loaded ? (
        <SpinLoader />
      ) : (
        <div
          ref={scrollableRef}
          className="relative divide-y border-t-2 divide-neutral-300 h-[75vh] overflow-auto pr-3 pl-3 shadow-md"
        >
          {allList.length === 0 && loaded && <NoDataFound />}
          <div className="flex flex-col w-full mt-5 space-y-4 pb-5">
            {appointmentData.map((appointment, index) => (
              <AppointmentItem
                key={index}
                data={appointment}
                handleSubmit={handleNoteSubmit}
                noteInput={noteInput}
                setNoteInput={setNoteInput}
                isClose={isModalOpen}
                setIsClose={setIsModalOpen}
                loaded={loaded}
              />
            ))}
          </div>
        </div>
      )}
      {!loaded && allList.length > 0 && (
        <div className="flex justify-center mt-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
