import React, { useEffect, useState } from "react";
import Button from "../form/Button";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "../elements/Modal";
import { JoinCallModal } from "../elements/JoinCallModal";
import TextAreaAutoSize from "../form/TextareaAutoSize";
import { submitBtn } from "../elements/language";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../socket/SocketProvider";
import { JoinChatModal } from "../elements/JoinChatModal";
import { setSelectedAppointment } from "../../store/slice/appointmentSlice";

const titleLang = {
  en: "Write Note",
  it: "Scrivi nota",
  es: "Escribir nota",
};

const AppointmentItem = ({
  data,
  noteInput,
  setNoteInput = () => {},
  handleSubmit = () => {},
  isClose,
  setIsClose = () => {},
  loaded = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [joinModal, setJoinModal] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const language = useSelector((state) => state?.languageSlice?.data);
  const callData = useSelector((state) => state?.callingSlice?.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const localAppointmentData = localStorage.getItem("appointmentData");
  const appointmenData =
    localAppointmentData && JSON.parse(localStorage.getItem("appointmentData"));

  const callerData = {
    ...callData,
    mode: data?.mode,
    appointmentId: data?.id,
    consultantId: data?.clientData?._id,
    appointmentStartTime: data?.details?.appointmentStartTime,
  };

  useEffect(() => {
    isClose && setIsModalOpen(false);
  }, [isClose]);

  const openModal = (data) => {
    localStorage.setItem("selectedAppointment", data?.id);
    dispatch(setSelectedAppointment(data));
    setNoteInput(data?.notes?.message);
    setIsModalOpen(true);
    // setselectedNoteData(data);
    setIsClose(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.removeItem("selectedAppointment");
    setIsClose(true);
    dispatch(setSelectedAppointment(null));
  };

  return (
    <div className="relative">
      <div className="block lg:flex justify-between gap-5 md:py-1 space-y-5 lg:space-y-0 md:border-0 border border-slate-200 md:px-0 px-4 py-4 rounded-md">
        <NavLink
          className="md:flex block items-center gap-5 flex-shrink md:space-y-0 space-y-4"
          to={data.status == "close" && `/appointments-details/${data?.id}`}
        >
          <div className="rounded-2xl bg-prontopsy-blue py-2 flex flex-col items-center justify-center md:w-32 w-24 flex-shrink-0">
            <div className="text-white md:text-[32px] text-[22px] font-bold font-RobotoSlab">
              {data.date}
            </div>
            <div className="text-white md:text-base text-sm font-bold font-RobotoSlab">
              {data.month}
            </div>
            <div className="text-white md:text-sm text-xs font-normal font-RobotoSlab">
              {data.time}
            </div>
          </div>
          <div className="flex-shrink space-y-2">
            <div className="text-xs !text-prontopsy-blue font-bold italic font-UnisonPro uppercase">
              {data.note}
            </div>
            <div
              className="text-slate-600 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed"
              dangerouslySetInnerHTML={{ __html: data.paymentInfo }}
            ></div>
          </div>
        </NavLink>
        {/* Add Note */}

        <div className="flex lg:justify-between items-center gap-3 flex-shrink-0 justify-end">
          {(data?.status == "active" || data?.status == "close") && (
            <>
              <div className="relative">
                <Button
                  buttonLabel={data.buttonTextPink}
                  buttonLabelClasses={
                    "!uppercase !text-" +
                    data.buttonColor +
                    " !text-base !whitespace-nowrap !font-medium"
                  }
                  buttonIconPosition={"left"}
                  buttonIcon={data.buttonIcon}
                  buttonClasses={
                    "!bg-transparent !rounded-xl !px-2 !h-12 !text-prontopsy-pink !border-2 !border-prontopsy-pink"
                  }
                  buttonFunction={() => {
                    openModal(data);
                  }}
                />
              </div>
            </>
          )}
          {data?.status == "inProgress" && data.isTrue && (
            <div className="relative">
              <Button
                buttonLabel={data.buttonTextBlue}
                buttonLabelClasses={
                  "!uppercase !text-white !text-base !whitespace-nowrap !font-medium"
                }
                buttonClasses={
                  "!bg-prontopsy-blue !rounded-xl !px-6 !h-12 !text-white"
                }
                buttonFunction={() => {
                  if (data?.mode === "video" || data?.mode === "voice") {
                    if (appointmenData?.appointmentId && data?.sessionToken) {
                      // Redirect to the chats page
                      window.location.href = `/chats/${appointmenData.appointmentId}`;
                      // If you prefer using navigate() (React Router), uncomment the line below
                      // navigate(`/chats/${appointmenData.appointmentId}`);
                    } else {
                      console.error(
                        "Appointment ID or session token is missing."
                      );
                    }
                  } else {
                    if (appointmenData?.appointmentId) {
                      // Redirect to the text chat page
                      window.location.href = `/text-chat/${appointmenData.appointmentId}`;
                      // If you prefer using navigate(), uncomment the line below
                      // navigate(`/text-chat/${appointmenData.appointmentId}`);
                    } else {
                      console.error("Appointment ID is missing.");
                    }
                  }
                }}
                isDisable={
                  appointmenData &&
                  appointmenData?.callerData &&
                  appointmenData?.callerData?.mode !== "chat"
                    ? appointmenData.sessionId &&
                      appointmenData?.appointmentId === data?.id
                      ? false
                      : true
                    : appointmenData?.appointmentId === data?.id
                    ? false
                    : true
                }
              />
            </div>
          )}
        </div>
      </div>

      <Modal isOpen={isModalOpen} titleShow={false}>
        <div className="relative mt-2">
          <div className="absolute right-0 -top-6">
            <Button
              buttonLabel={""}
              buttonLabelClasses={
                "!uppercase !text-" + data &&
                data?.buttonColor +
                  " !text-base !whitespace-nowrap !font-medium"
              }
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonClasses={
                "!bg-transparent  !px-0 !py-0 !h-4 !text-prontopsy-pink  !text-2xl mt-2  "
              }
              buttonFunction={closeModal}
            />
          </div>

          <div className="col-span-12 lg:col-span-6">
            <div className="relative">
              <TextAreaAutoSize
                label={`*${titleLang?.[language]}`}
                iconClasses={"hidden"}
                inputPlaceholder={"Write your note here .."}
                inputType={Text}
                divClasses={"h-12 bg-transparent !border-black rounded-none"}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                inputClasses={"!bg-transparent !h-12"}
                inputName={"name"}
                onChange={(e) => setNoteInput(e.target.value)}
                inputValue={noteInput}
              />
            </div>

            <div className="relative w-full mt-3">
              <Button
                buttonLabel={submitBtn?.[language]}
                buttonLabelClasses={
                  "!uppercase !text-" + data &&
                  data?.buttonColor +
                    " !text-base !whitespace-nowrap !font-medium"
                }
                buttonClasses={
                  "!py-5 !px-6 !h-5 !text-white  !text-xl  !bg-prontopsy-pink text-white "
                }
                buttonFunction={() => handleSubmit()}
                isDisable={!loaded}
              />
            </div>
          </div>
        </div>
      </Modal>

      <JoinCallModal
        data={{
          ...data?.clientData,
          name: data?.clientData?.firstName + " " + data?.clientData?.lastName,
        }}
        sessionId={data?.sessionId}
        sessionToken={data?.sessionToken}
        callerData={callerData}
        isOpen={joinModal}
        setIsOpen={setJoinModal}
        modalTitle={"Join call"}
      />
      <JoinChatModal
        isOpen={isChatModalOpen}
        setIsOpen={setIsChatModalOpen}
        data={data?.clientData}
        callerData={callerData}
        from="appointment"
      />
    </div>
  );
};

export default AppointmentItem;
