import React, { useEffect, useState, useRef } from "react";
import AppointmentItem from "../../../../single/AppointmentItem";
import { useSelector } from "react-redux";
import { appointmentLang } from "../../../language";
import dayjs from "dayjs";
import SpinLoader from "../../../table/SpinLoader";
import { fetchAppointmentList } from "../../../../../service/appointmentService";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../../NoDataFound";

const langConfig = {
  payment: {
    en: "Payment amount",
    es: "Monto del pago",
    it: "Importo del pagamento",
  },
  status: {
    en: "Payment status",
    es: "Estado de pago",
    it: "Stato del pagamento",
  },
  id: {
    en: "Appointment ID",
    es: "ID de cita",
    it: "ID di appuntamento",
  },
};

const AppointmentInProgress = () => {
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const language = useSelector((state) => state.languageSlice.data);
  const [appointmentData, setAppointmentData] = useState([]);
  const token = localStorage.getItem("auth_token");
  const [loaded, setLoaded] = useState(true);
  const [list, setList] = useState([]);

  const [limit, setLimit] = useState(10);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [allList, setAllList] = useState([]);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);

  useEffect(() => {
    fetchAppointmentList(
      token,
      { limit: limit, "status[0]": "inProgress", page: page },
      setList,
      setLoaded,
      setHasNextPage
    ).then((res) => {
      if (res?.data?.docs?.status === 200) {
        let metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        page !== 1
          ? setAllList((prevList) => [...prevList, ...res.data.docs.data])
          : setAllList(res.data.docs.data);
      }
    });
  }, [page]);
  useEffect(() => {
    if (scrolledToBottom && hasNextPage) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage]);

  useEffect(() => {
    let info =
      list &&
      allList.length > 0 &&
      allList.map((elm) => ({
        id: elm._id,
        date: dayjs(elm?.createdAt).format("D"),
        month: dayjs(elm?.createdAt).format("MMMM"),
        time:
          dayjs(elm?.details?.appointmentStartTime).format("h:mm") +
          "  - " +
          dayjs(elm?.details?.appointmentEndTime).format("h:mm A"),
        note: ` ${appointmentLang?.inProgressAppointment?.[language]}
           
            ${
              roleCode === "consultant"
                ? elm?.clientData?.firstName && elm?.clientData?.lastName
                  ? elm.clientData.firstName + " " + elm.clientData.lastName
                  : ""
                : elm?.consultantData?.firstName &&
                  elm?.consultantData?.lastName
                ? elm.consultantData.firstName +
                  " " +
                  elm.consultantData.lastName
                : ""
            }`,
        clientData: elm?.clientData,
        sessionId: elm?.sessionId,
        sessionToken: elm?.sessionToken,
        paymentInfo: `<p>${langConfig?.payment?.[language]}: EUR ${elm?.details?.capturedAmount}</p><p>${langConfig?.status?.[language]}: ${elm?.details?.paymentStatus}</p><p>${langConfig?.id?.[language]}: ${elm?.details?.appointmentId}</p>`,
        buttonTextPink: appointmentLang?.addNote?.[language],
        buttonTextBlue: appointmentLang?.active?.[language],
        buttonIcon: "fa-solid fa-file",
        status: elm?.status,
        isTrue: true,
        mode: elm?.service?.mode?.title?.en,
      }));
    setAppointmentData(info);
  }, [list, language]);
  // console.log({ allList });

  return (
    <div>
      {" "}
      {allList.length === 0 && !loaded ? (
        <SpinLoader />
      ) : (
        <div
          ref={scrollableRef}
          className="relative divide-y border-t-2 divide-neutral-300 h-[75vh] overflow-auto pr-3 pl-3 shadow-md"
        >
          {allList.length == 0 && loaded && <NoDataFound />}
          <div className="flex flex-col w-full mt-5 space-y-4 pb-5">
            {appointmentData &&
              appointmentData.length > 0 &&
              appointmentData.map((appointment, index) => {
                return <AppointmentItem key={index} data={appointment} />;
              })}
          </div>
        </div>
      )}{" "}
      {/* {allList.length == 0 && loaded && <NoDataFound />} */}
      {!loaded && allList.length > 0 && (
        <div className="flex justify-center mt-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
        </div>
      )}
    </div>
  );
};

export default AppointmentInProgress;
